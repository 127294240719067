export const StoreData = [
  {
    country: 'TURKEY',
    city: [
      {
        name: 'ISTANBUL',
        description: [
          'Kılıçali Paşa Mah. Meclis-i Mebusan Cad.',
          'No: 8 İç Kapı No: 102, 34433 Beyoğlu/',
          'İstanbul',
          '+90 212 558 69 69',
        ],
        workHour: '10:00 - 22:00',
        latitude: 28.985362760597585,
        longitude: 41.02748727482876,
      },
    ],
  },
  {
    country: 'ALMANYA',
    city: [
      {
        name: 'METZINGEN',
        description: ['+49 7123 969 61 55'],
        workHour: '10:00 - 22:00',
        latitude: 9.2836025499285,
        longitude: 48.53823949095808,
      },
    ],
  },
  {
    country: 'ABU DABI',
    city: [
      {
        name: 'DUBAI',
        description: [''],
        workHour: '10:00 - 22:00',
        latitude: 54.402040648718014,
        longitude: 24.489847878448312,
      },
    ],
  },
  {
    country: 'ITALYA',
    city: [
      {
        name: 'MILANO',
        description: [''],
        workHour: '10:00 - 22:00',
        latitude: 9.1898252826371,
        longitude: 45.465779379675304,
      },
    ],
  },
];
