import MobileHeadImage from '../../../assets/img/home/head/mobile-head.png';
import HeadImage from '../../../assets/img/home/head/head.png';
import style from './head.module.scss';
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import { LangData } from '../../../utils/LangData';

const Head = ({ width, lang }) => {
  return (
    <div id="head" className={style.head}>
      <Controller>
        <Scene offset={-300} triggerElement="#trigger" duration={1000}>
          {progress => {
            console.log('progress', progress);
            return (
              <>
                <Tween totalProgress={progress} paused>
                <img src={width > 940 ? HeadImage : MobileHeadImage} />
                  {/*<video autoPlay src={width > 940 ? HeadImage : MobileHeadImage} />
                  <div className={style.video}>
                    <video playsInline autoPlay muted loop>
                      <source
                        src={width > 940 ? HeadImage : MobileHeadImage}
                        type="video/mp4"
                      />
                    </video>
                  </div>*/}
                </Tween>
                
                <div
                  style={{
                    opacity: progress > 0.7 ? 0 : 1,
                  }}
                  className={style.info}
                >
               

                  <div>
                    {width > 940 ? (
                      <h1>
                        EVERYONE IS <br />
                        <span />
                        AFTER THEM
                      </h1>
                    ) : (
                      <h1>
                        EVERYONE IS
                        <br />
                        AFTER THEM
                      </h1>
                    )}
                    <h2>
                      but the only thıng <br />
                      <span />
                      they’re after
                    </h2>
                  </div>
                  <div className={style.second}>
                    
                    {width > 940 ? (
                      <h3>
                        {LangData[lang].shadesOfGrey.headCap.one} <br/> {LangData[lang].shadesOfGrey.headCap.two}
                      </h3>
                    ) : (
                      <h3>{LangData[lang].shadesOfGrey.headCap.three}</h3>
                    )}
                    <h4>
                    {LangData[lang].shadesOfGrey.headCap.four} <br/>
                   <span> {LangData[lang].shadesOfGrey.headCap.five} </span>
                    </h4>
                  </div>
                </div>
         
                {width > 940 ? null : (
                  <div className={style.scroll}>
                    {LangData[lang].home.head.scroll}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="8"
                      viewBox="0 0 10 8"
                    >
                      <path
                        id="Polygon_2"
                        data-name="Polygon 2"
                        d="M5,0l5,8H0Z"
                        transform="translate(10 8) rotate(180)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                )}
              </>
            );
          }}
        </Scene>
      </Controller>
    </div>
  );
};

export default Head;
