import style from './login.module.scss';
import { PageWrapper, Newsletter } from '../../components';
import LoginImage from '../../assets/img/brand/login-image.png';
import { useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { BASE_URL } from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LangData } from '../../utils/LangData';

const Login = () => {
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const currentLang = useSelector(state => state.settings.setting.lang);

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, LangData[currentLang].login.error.username)
      .required(LangData[currentLang].login.error.required),
    password: Yup.string()
      .min(8, LangData[currentLang].login.error.password)
      .required(LangData[currentLang].login.error.required),
  });

  const loginSubmit = values => {
    axios
      .post(`${ApiRoutes.auth.login}`, {
        username: values.username,
        password: values.password,
      })
      .then(res => {
        console.log(res.data);
        if (res.data.item1 && res.data.item2) {
          localStorage.setItem('token', res.data.item2);

          axios.defaults.baseURL = BASE_URL;
          axios.interceptors.request.use(
            async config => {
              // const token = await AsyncStorage.getItem('token');

              config.headers['Authorization'] = `Bearer ${res.data.item2}`;

              return config;
            },
            error => {
              return Promise.reject(error);
            }
          );

          navigate('/');
        } else if (res.data.item1 === null && res.data.item2 === null) {
          setError('loginError');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <div className={style.left}>
          <img src={LoginImage} alt="login" />
        </div>
        <div className={style.right}>
          <h1>{LangData[currentLang].login.title}</h1>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={values => {
              loginSubmit(values);
              console.log(values);
              // createPayment(values);
            }}

            // innerRef={formikRef}
          >
            {({ errors, touched, values, handleSubmit }) => (
              <Form id="payment" onSubmit={handleSubmit}>
                <label>
                  <Field
                    type={'test'}
                    id={'username'}
                    name={'username'}
                    placeholder={LangData[currentLang].login.userName}
                    value={values.username}
                  />
                </label>
                {errors.username && touched.username ? (
                  <span>{errors.username}</span>
                ) : null}

                <label>
                  <Field
                    type={'password'}
                    id={'password'}
                    name={'password'}
                    placeholder={LangData[currentLang].login.password}
                    value={values.password}
                  />
                </label>
                {errors.password && touched.password ? (
                  <span>{errors.password}</span>
                ) : null}

                {error === 'loginError' ? (
                  <span>{LangData[currentLang].login.errorTitle}</span>
                ) : null}
                <button type="submit">
                  {LangData[currentLang].login.login}
                </button>
              </Form>
            )}
          </Formik>
          <div className={style.bottom}>
            <div
              onClick={() => setRemember(!remember)}
              className={`${style.remember} `}
            >
              <div className={`${style.square} ${remember && style.active}`} />
              <h5>{LangData[currentLang].login.rememberMe}</h5>
            </div>
            <a href="/login">
              {LangData[currentLang].login.forgot[0]} <br />{' '}
              {LangData[currentLang].login.forgot[1]}
            </a>
          </div>
          <a className={style.register} href="/register">
            {LangData[currentLang].login.register}
          </a>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Login;
