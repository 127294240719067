import { ADD_TO_CART, REMOVE_TO_CART, CART_CHANGE_QUANTITY } from '../type';

const initialState = {
  cart: [],
  quantity: 0,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      console.log(action.payload);
      return {
        ...state,
        quantity: 1,
        // cart: [action.payload, ...state.cart],
      };

    case REMOVE_TO_CART:
      return {
        ...state,
        quantity: 0,
        // cart: state.cart.filter(i => i.id !== action.payload),
      };
    case CART_CHANGE_QUANTITY:
      console.log(action.payload);
      return {
        ...state,
        cart: [
          ...state.cart.filter(i => i.id !== action.payload.id),
          action.payload,
        ],
      };
    default:
      return state;
  }
}
