const GarantiPaymentForm = ({ value }) => {
  return (
    <form
      method="post"
      role="form"
      style={{
        height: 0,
        visibility: 'hidden',
        width: 0,
      }}
      id="garantiForm"
      action="https://sanalposprov.garanti.com.tr/servlet/gt3dengine"
    >
      <input type="hidden" name="mode" id="mode" value="TEST" />
      <input type="hidden" name="apiversion" id="apiversion" value="512" />
      <input
        type="hidden"
        name="terminalprovuserid"
        id="terminalprovuserid"
        value="PROVAUT"
      />
      <input
        type="hidden"
        name="secure3dsecuritylevel"
        id="secure3dsecuritylevel"
        value="3D_FULL"
      />
      <input
        type="hidden"
        name="terminaluserid"
        id="terminaluserid"
        value="GARANTI"
      />
      <input
        type="hidden"
        name="terminalmerchantid"
        id="terminalmerchantid"
        value="2364497"
      />
      <input type="hidden" name="txntype" id="txntype" value="sales" />
      <input
        type="hidden"
        name="txncurrencycode"
        id="txncurrencycode"
        value="949"
      />
      <input
        type="hidden"
        name="txninstallmentcount"
        id="txninstallmentcount"
        value=""
      />
      <input type="hidden" name="txnamount" id="txnamount" value={value.payment.pay} />
      <input
        type="hidden"
        name="orderid"
        id="orderid"
        value={value.payment.orderId}
      />
      <input type="hidden" name="terminalid" id="terminalid" value="10270737" />
      <input
        type="hidden"
        name="successurl"
        id="successurl"
        value={value.payment.successUrl}
        // value={'http://demo.fratellidiamanti.com/product'}
      />
      <input
        type="hidden"
        name="errorurl"
        id="errorurl"
        value={value.payment.errorUrl}
        // value={'http://demo.fratellidiamanti.com/product'}
      />

      <input
        type="hidden"
        name="customeremailaddress"
        id="customeremailaddress"
        value="eticaret@garanti.com.tr"
      />
      <input
        type="hidden"
        name="customeripaddress"
        id="customeripaddress"
        value={value.ip ? value.ip : '192.168.0.1'}
      />
      <input
        type="hidden"
        name="companyname"
        id="companyname"
        Value="FRATELLI"
      />
      <input type="hidden" name="lang" id="lang" Value="tr" />
      <input
        type="hidden"
        name="txntimestamp"
        id="txntimestamp"
        value={value.date}
      />
      <input type="hidden" name="refreshtime" id="refreshtime" value="1" />
      <input
        type="hidden"
        name="secure3dhash"
        id="secure3dhash"
        value={value.payment.hashData}
      />
      <input name="cardholdername" value={value.card.name} />
      <input
        name="cardnumber"
        value={value.card.cardNumber.split(/\s/).join('')}
      />
      <input name="cardexpiredatemonth" value={value.card.month} />
      <input name="cardexpiredateyear" value={value.card.year} />
      <input name="cardcvv2" value={value.card.cvc} />
    </form>
  );
};

export default GarantiPaymentForm;
