export const ApiRoutes = {
  auth: {
    register: 'User/InsertUser',
    login: 'Login/Login',
  },
  home: {
    get: 'Home/GetHome',
  },
  product: {
    get: 'Product/GetProductSlug',
    getAll: 'Product/GetProducts',
  },
  collection: {
    get: 'Product/GetCollections',
  },
  basket: {
    get: 'Basket/GetBasket',
    add: 'Basket/AddBasket',
    update: 'Basket/UpdateBasket',
    delete: 'Basket/DeleteBasket',
  },
  address: {
    get: 'Address/GetAddress',
    add: 'Address/AddAddress',
    update: 'Address/UpdateAddress',
  },
  payment: {
    get: 'Payment/GetPayment',
  },
  order: {
    get: 'Order/GetOrders',
  },
  newsletter: {
    set: 'Home/AddJournals',
  },
};
