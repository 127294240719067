import style from './summary.module.scss';
import ProductImage from '../../../assets/img/dummy/product.png';
import ProductImage2 from '../../../assets/img/home/render_png.png';
import { LangData } from '../../../utils/LangData';
import { useSelector } from 'react-redux';

const Summary = ({ data, withProduct, click, cart, section }) => {
  const priceData = data.map(item =>
    parseFloat(Number(item.price) * item.quantity)
  );
  const currentLang = useSelector(state => state.settings.setting.lang);
  console.log('pricedata', data);
  const totalPrice = priceData.reduce((a, b) => a + b);
  // console.log(priceData);

  return (
    <div className={style.summary}>
      <div className={style.title}>
        <h2>{LangData[currentLang].basket.orderSummary}</h2>
        {/*  <button>SEPETİ PAYLAŞ</button>*/}
      </div>
      {withProduct ? (
        <div>
          {data.map(item => {
            return (
              <div className={style.product}>
                <img src={item.productPhoto} alt="product" />
                <div className={style.productInfo}>
                  <h3>{item.name}</h3>
                  <h4>
                    {new Intl.NumberFormat('tr-TR', {
                      style: 'currency',
                      currency: 'TRY',
                      minimumFractionDigits: 0,
                    }).format(totalPrice)}
                  </h4>
                  <h5>{item.quantity} {LangData[currentLang].basket.piece}</h5>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      <ul>
        <li>
          <span>{LangData[currentLang].basket.subTotal}</span>
          <h5>
            {new Intl.NumberFormat('tr-TR', {
              style: 'currency',
              currency: 'TRY',
              minimumFractionDigits: 0,
            }).format(totalPrice)}{' '}
          </h5>
        </li>
        {/*<li>
          <span>KARGO ÜCRETİ</span>
          <h5>₺0</h5>
          </li>*/}
        <li>
          <span>{LangData[currentLang].basket.discount}</span>
          <h5>₺0</h5>
        </li>
        <li>
          <span>{LangData[currentLang].basket.totalAmount}</span>
          <h5>
            {new Intl.NumberFormat('tr-TR', {
              style: 'currency',
              currency: 'TRY',
              minimumFractionDigits: 0,
            }).format(totalPrice)}{' '}
          </h5>
        </li>
      </ul>
      <div className={style.buttonWrapper}>
        {section === 'payment' ? (
          <button form="garantiForm" type="submit">
            SATIN AL
          </button>
        ) : (
          <button onClick={click}>{LangData[currentLang].basket.buy}</button>
        )}
        <button>{LangData[currentLang].basket.discountCoupon}</button>
      </div>
    </div>
  );
};

export default Summary;
