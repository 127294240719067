import style from './productcomp.module.scss';

const ProductComp = ({ image, title, price, home, link, priceLine }) => {
  return (
    <a href={link} className={style.product}>
      <img className={home ? style.home : null} src={image} />
      <h4>{title}</h4>
      <p>EXTRAIT DE PARFUM</p>
      <h5>
        {priceLine ? (
          <>
            {' '}
            <p className={style.cross}>
              {' '}
              <span className={style.striketextcolor}>
                {' '}
                {new Intl.NumberFormat('tr-TR', {
                  style: 'currency',
                  currency: 'TRY',
                  minimumFractionDigits: 0,
                }).format(priceLine)}
              </span>
            </p>{' '}
          </>
        ) : null}
        {new Intl.NumberFormat('tr-TR', {
          style: 'currency',
          currency: 'TRY',
          minimumFractionDigits: 0,
        }).format(price)}
      </h5>
    </a>
  );
};

export default ProductComp;
