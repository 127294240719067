import style from './products.module.scss';
import ProductImage from '../../../assets/img/dummy/product.png';
import ProductImage2 from '../../../assets/img/home/render_png.png';
import { changeQuantity } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { ApiRoutes } from '../../../utils/ApiRoutes';
import { LangData } from '../../../utils/LangData';
import { useSelector } from 'react-redux';

const ProductCard = ({
  id,
  image,
  title,
  price,
  info,
  quantity,
  productId,
  updated,
  deleteClick,
  priceLine,
}) => {
  const dispatch = useDispatch();
  const currentLang = useSelector(state => state.settings.setting.lang);
  const changeFunc = type => {
    axios
      .post(ApiRoutes.basket.update, {
        id: id,
        productId: productId,
        quantity:
          type === 'up'
            ? quantity + 1
            : type === 'down' && quantity !== 1
            ? quantity - 1
            : quantity,
      })
      .then(res => {
        console.log(res.data);
        updated();
      })
      .catch(err => {
        console.log(err);
      });

    // dispatch(
    //   changeQuantity({
    //     id: id,
    //     title: title,
    //     price: price,
    //     info: info,
    //     quantity:
    //       type === 'up'
    //         ? quantity + 1
    //         : type === 'down' && quantity !== 1
    //         ? quantity - 1
    //         : quantity,
    //   })
    // );
  };

  return (
    <div className={style.productCard}>
      <img alt={title} src={image} className={style.mainImage} />
      <div className={style.info}>
        <h3>{title}</h3>
        <h4>
          {priceLine ? (
            <>
              {' '}
              <p className={style.cross}>
                {' '}
                <span className={style.striketextcolor}>
                  {' '}
                  {new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: 'TRY',
                    minimumFractionDigits: 0,
                  }).format(priceLine)}
                </span>
              </p>{' '}
            </>
          ) : null}
          {new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY',
            minimumFractionDigits: 0,
          }).format(price)}{' '}
        </h4>
        <h5>{info}</h5>
        <div className={style.buttonWrapper}>
          <div className={style.quantity}>
            <button onClick={() => changeFunc('down')}>-</button>
            <span>{quantity}    {LangData[currentLang].basket.piece}</span>
            <button onClick={() => changeFunc('up')}>+</button>
          </div>
        </div>
        <button onClick={deleteClick} className={style.delete}>
          {LangData[currentLang].basket.delete}
        </button>
      </div>
      <div className={style.mobileButtonWrapper}>
        <button>EDIT</button>
        <button onClick={deleteClick}>{LangData[currentLang].basket.delete}</button>
      </div>
    </div>
  );
};

const Products = ({ data, cart, updated, deleteClick }) => {
  const currentLang = useSelector(state => state.settings.setting.lang);

  return (
    <div className={style.products}>
      <h1>{LangData[currentLang].basket.basketCaption}</h1>
      <div className={style.productWrapper}>
        {data.map(item => {
          return (
            <ProductCard
              title={item.name}
              image={item.productPhoto}
              info={
                'FRATELLI DIAMANTI - DIAMOND COLLECTION - EXTRAIT DE PARFUM - 65 ML / NATURAL SPRAY'
              }
              price={item.price * item.quantity}
              piceLine={item.piceLine * item.quantity}
              id={item.id}
              quantity={item.quantity}
              productId={item.productId}
              updated={updated}
              deleteClick={() => {
                deleteClick({
                  id: item.id,
                  productId: item.productId,
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Products;
