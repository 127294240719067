import { ADD_TO_CART, REMOVE_TO_CART, CART_CHANGE_QUANTITY } from '../type';

export const addToCart = payload => {
  return {
    type: ADD_TO_CART,
    payload: payload,
  };
};

export const removeToCart = payload => {
  return {
    type: REMOVE_TO_CART,
    payload: payload,
  };
};

export const changeQuantity = payload => {
  return {
    type: CART_CHANGE_QUANTITY,
    payload: payload,
  };
};
