import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import style from './address.module.scss';
import { CityData } from '../../../utils/CityData';
import axios from 'axios';
import { ApiRoutes } from '../../../utils/ApiRoutes';
import { LangData } from '../../../utils/LangData';
import { useSelector } from 'react-redux';


const AddressSchema = Yup.object().shape({
  caption: Yup.string()
    .min(3, 'Doğru bir caption  giriniz.')
    .required('Zorunlu alan'),
  province: Yup.string().min(3, 'Şehir seçiniz.').required('Zorunlu alan'),
});

const AddressForm = ({ doneForm, editData }) => {
  const [error, setError] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);

  const addAdress = values => {
    axios
      .post(ApiRoutes.address.add, {
        caption: values.caption,
        province: values.province,
        district: values.district,
        address: values.address,
      })
      .then(res => {
        console.log(res.data);
        doneForm();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const editAddress = values => {
    axios
      .post(ApiRoutes.address.update, {
        id: editData.id,
        caption: values.caption,
        province: values.province,
        district: values.district,
        address: values.address,
      })
      .then(res => {
        console.log(res.data);
        doneForm('edit');
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className={style.formWrapper}>
      <Formik
        initialValues={{
          caption: editData ? editData.caption : '',
          province: editData ? editData.province : '',
          district: editData ? editData.district : '',
          address: editData ? editData.address : '',
        }}
        validationSchema={AddressSchema}
        onSubmit={values => {
          console.log(values);
          if (editData) {
            editAddress(values);
          } else {
            addAdress(values);
          }
        }}

        // innerRef={formikRef}
      >
        {({ errors, touched, values, handleSubmit }) => (
          <Form id="payment" onSubmit={handleSubmit}>
            <label>
              <Field
                type={'text'}
                id={'caption'}
                name={'caption'}
                placeholder={LangData[currentLang].basket.title}
                value={values.caption}
              />
            </label>
            {errors.caption && touched.caption ? (
              <span>{errors.caption}</span>
            ) : null}
            <label>
              <Field as="select" name="province">
                <option value={''} selected disabled>
                {LangData[currentLang].basket.city}
                </option>
                {CityData.map(item => {
                  return <option value={item}>{item}</option>;
                })}
              </Field>
            </label>

            {errors.province && touched.province ? (
              <span>{errors.province}</span>
            ) : null}

            <label>
              <Field
                type={'text'}
                id={'district'}
                name={'district'}
                placeholder={LangData[currentLang].basket.province}
                value={values.district}
              />
            </label>
            {errors.district && touched.district ? (
              <span>{errors.district}</span>
            ) : null}

            <label className={style.textareaWrapper}>
              <Field
                as={'textarea'}
                id={'address'}
                name={'address'}
                placeholder={LangData[currentLang].basket.address}
                value={values.address}
              />
            </label>
            {errors.address && touched.address ? (
              <span>{errors.address}</span>
            ) : null}

            {error === 'loginError' ? (
              <span>Giriş bilgileri hatalıdır</span>
            ) : null}
            <button type="submit">
              {editData ? LangData[currentLang].basket.editAddress : LangData[currentLang].basket.addAddress}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddressForm;
