import {
  PageWrapper,
  FlashPhoto,
  Magazine,
  TripleCard,
  ProductCard,
  VideoCard,
  TextPart,
  HomeFooter,
  Crystal,
  Header,
  MobileMenu,
  Footer,
  Newsletter,
  HomeHead,
  ProductComp,
  Signature,
  BottomInfo,
} from '../../components';
import style from './productpage.module.scss';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../helpers/windowWidth';
import { useEffect, useState } from 'react';
import Triple1 from '../../assets/img/new-home/triple/triple-1.png';
import Triple2 from '../../assets/img/new-home/triple/triple-2.png';
import Triple3 from '../../assets/img/new-home/triple/triple-3.png';
import { Scene, Controller } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import ProductImage from '../../assets/img/new-home/product-image.png';
import Feed1 from '../../assets/img/new-home/feed/ceyhan.png';
import Feed2 from '../../assets/img/new-home/feed/shades.png';
import SignatureIcon from '../../assets/img/brand/signature-image.png';
import { BigFDIcon } from '../../assets/icon';
import { useLocation } from 'react-router-dom';
import { LangData } from '../../utils/LangData';

const Home = () => {
  const { width } = useWindowDimensions();
  const cartState = useSelector(state => state.cart.cart);
  const [basketData, setBasketData] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);
  const [data, setData] = useState();
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  useEffect(() => {
    axios
      .get(`${ApiRoutes.basket.get}`)
      .then(async res => {
        console.log('basketData', res.data);
        await setBasketData(res.data);
      })
      .catch(err => {
        console.log(err);
      });

    axios
      .get(`${ApiRoutes.product.get}?slug=${path}`)
      .then(res => {
        console.log('slug', res.data);
        setData(res.data[0]);
      })
      .catch(err => {
        console.log(err);
      });

    localStorage.removeItem('redirectProduct');
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log('baskk', basketData);

  return (
    <div className={style.home}>
      {data ? (
        <>
          {width < 940 ? (
            <MobileMenu
              lang={currentLang}
              home
              cart={basketData ? basketData : 0}
              productLink={`/product/${data.slug}`}
            />
          ) : (
            <Header
              productLink={`/product/${data.slug}`}
              lang={currentLang}
              home
              cart={basketData ? basketData : 0}
            />
          )}
          {scrollPosition < 50 ? (
            <a className={style.buy} href={`/product/${data.slug}`}>
             
              {LangData[currentLang].home.head.buttonText}
            </a>
          ) : null}
          <HomeHead
            video={data.sliderVideo.desktop}
            videoMobile={data.sliderVideo.mobile}
            lang={currentLang}
            width={width}
            disableDiscover
            text={data.sliderText[currentLang]}
          />
          <div className={style.singleProduct}>
            <ProductComp
              image={data.productPhoto}
              price={data.price}
              priceLine={data.priceLine}
              title={data.title[currentLang]}
              link={`/product/${data.slug}`}
            />
          </div>
          {width > 940 ? (
            <div className={style.triple}>
              {data.bottomPhoto.map(item => {
                return <img src={item.link} />;
              })}
            </div>
          ) : (
            <Controller>
              <div id="triple222" className={style.tripleWrapper}>
                <img src={data.bottomPhoto[0].link} />
                <Scene
                  duration={1000}
                  triggerElement={'#triple222'}
                  pin={'#triple222'}
                  triggerHook={0}
                >
                  {progress => (
                    <Tween
                      from={{
                        x: 100,
                      }}
                      to={{
                        x: -360,
                      }}
                      totalProgress={progress}
                      paused
                    >
                      <div className={style.mobileImageWrapper}>
                        <img src={data.bottomPhoto[1].link} />
                        <img src={data.bottomPhoto[2].link} />
                      </div>
                    </Tween>
                  )}
                </Scene>
              </div>
            </Controller>
          )}
          {/* <div className={style.signatureWrapper}>
            <Signature lang={currentLang} />
                    </div>*/}
          <BottomInfo lang={currentLang} />
          <Newsletter lang={currentLang} />
          <div className={style.footerWrapper}>
            <Footer lang={currentLang} width={width} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Home;
