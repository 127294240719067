import { PageWrapper } from '../../components';
import style from './returnpolicy.module.scss';

const ReturnPolicy = () => {
  return (
    <PageWrapper>
      <div className={style.satisSozlesmesi}>
        <h1>İptal İade Koşulları</h1>

        <h2>İade Değişim:</h2>
        <p>
          Ambalajı açılmış, kullanılmış, tahrip edilmiş vb. şekildeki ürünlerin
          iadesi kabul edilmez. İade, orijinal ambalaj ile yapılmalıdır. Üründe
          ve ambalajında herhangi bir açılma, bozulma, kırılma, tahrip,
          yırtılma, kullanılma ve buna benzer durumlar tespit edildiği hallerde
          ve ürünün müşteriye teslim edildiği andaki hali ile iade edilememesi
          halinde ürün iade alınmaz ve bedeli iade edilmez. İade edilen ürün
          Fratelli Diamanti`ye ulaştıktan sonra teknik incelemeye gönderilir. Yapılan
          kontrollerde ürünün kullanıcı tarafından hatalı hale getirildiği
          raporu verilirse iade kabul edilemez.
        </p>

        <h2>Teslimattan kaynaklanan problemler:</h2>
        <p>
          Zarar görmüş paket durumunda; Zarar görmüş paketler teslim alınmayarak
          teslimatçı yetkiliye tutanak tutturulmalıdır. Eğer teslimatçı firma
          yetkilisi paketin hasarlı olmadığını düşünüyorsa, orada paketin
          açılarak ürünlerin hasarsız teslim edildiğini kontrol ettirme ve
          durumun yine bir tutanakla tespit edilmesini isteme hakkınız vardır.
          Paket tarafınızdan teslim alındıktan sonra teslimatçı firmanın
          görevini layıkıyla yaptığı kabul edilmiş sayılır. Paket kabul
          edilmemiş ve tutanak tutulmuş ise, durum, tutanağın sizde kalan
          kopyasıyla birlikte en kısa zamanda Fratelli Diamanti`ye
          bildirilmelidir. Fratelli Diamanti en kısa sürede yeni ve sağlıklı bir
          teslimatın gerçekleşmesini sağlayacaktır. Size teslim edilen ürünler
          ilgili herhangi bir problemle karşılaşmanız durumunda{' '}
          <a
            rel="noreferrer"
            target={'_blank'}
            href="mailto:destek@fratellidiamanti.com"
          >
            info@fratellidiamanti.com
          </a>{' '}
          adresinden bize ulaşabilirsiniz!
        </p>
      </div>
    </PageWrapper>
  );
};

export default ReturnPolicy;
