import style from './inspiration.module.scss';
import { LangData } from '../../utils/LangData';

const Inspiration = ({ lang, data }) => {
  return (
    <div className={style.inspiration}>
      <h2>{LangData[lang].product.inspiration.title}</h2>
      <p dangerouslySetInnerHTML={{ __html: data }}></p>
    </div>
  );
};

export default Inspiration;
