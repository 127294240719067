import style from './crystal.module.scss';

import CrystalBig from '../../../assets/img/home/crystal/crystal-big.png';
import CrystalMini from '../../../assets/img/home/crystal/crystal-mini.png';
import Crystal1 from '../../../assets/img/home/crystal/crystal-1.png';
import Crystal2 from '../../../assets/img/home/crystal/crystal-2.png';
import Crystal3 from '../../../assets/img/home/crystal/crystal-3.png';
import CrystalHead from '../../../assets/img/home/crystal/crystal-head.png';
import CrystalMobile1 from '../../../assets/img/home/crystal/crystal-mobile-1.png';
import CrystalMobile2 from '../../../assets/img/home/crystal/crystal-mobile-2.png';
import CrystalHeadMobile from '../../../assets/img/home/crystal/crystal-head-mobile.png';
import { CrystalHeadPart } from '../../../assets/icon';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import Diamond1 from '../../../assets/img/home/diamond/diamond-1.png';
import Diamond2 from '../../../assets/img/home/diamond/diamond-2.png';
import Diamond3 from '../../../assets/img/home/diamond/diamond-3.png';
import Diamond4 from '../../../assets/img/home/diamond/diamond-4.png';
import Diamond5 from '../../../assets/img/home/diamond/diamond-5.png';
import Diamond6 from '../../../assets/img/home/diamond/diamond-6.png';
import Diamond7 from '../../../assets/img/home/diamond/diamond-7.png';

import TextPart from '../TextPart/TextPart';
import { useEffect, useState } from 'react';
import { LangData } from '../../../utils/LangData';

const diamondData = [
  Diamond1,
  Diamond2,
  Diamond3,
  Diamond4,
  Diamond5,
  Diamond6,
  Diamond7,
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Crystal = ({ width, lang }) => {
  const [randomData, setRandomData] = useState(null);

  const xYRange = width > 940 ? 200 : 1500;
  const mobileXRange = 50;
  const rotateRange = width > 940 ? 400 : 400;

  useEffect(() => {
    setRandomData(
      diamondData.map(item => {
        return {
          x: getRandomInt(0, 1)
            ? getRandomInt(0, width > 940 ? xYRange : mobileXRange)
            : -getRandomInt(0, width > 940 ? xYRange : mobileXRange),
          y: getRandomInt(0, 1)
            ? getRandomInt(0, xYRange)
            : width > 940
            ? -getRandomInt(0, xYRange)
            : getRandomInt(0, xYRange),
          rotate: getRandomInt(0, 1)
            ? getRandomInt(0, rotateRange)
            : -getRandomInt(0, rotateRange),
        };
      })
    );
  }, []);

  return (
    <>
      {randomData ? (
        <div id="crystal" className={style.crystal}>
          <Controller>
            <Scene triggerElement="#crystal" duration={700}>
              {progress => {
                console.log('progress', progress);
                return (
                  <>
                    <div className={style.head}>
                      <img
                        src={width > 940 ? CrystalHead : CrystalHeadMobile}
                      />
                      <Tween
                        from={{
                          height: width > 940 ? 760 : 240,
                          clipPath: `polygon(0 0%, 100% 0%, 100% 100%, 0 100%)`,
                        }}
                        to={{
                          height: width > 940 ? 500 : 200,
                          clipPath: `polygon(0 60%, 100% 92%, 100% 100%, 0 100%)`,
                        }}
                        totalProgress={progress}
                        paused
                      >
                        <div className={style.headPart} />
                      </Tween>
                    </div>
                    <div className={style.wrapper}>
                      {/* <div className={style.diamondWrapper}>
                        {diamondData.map((item, index) => {
                          return (
                            <Scene
                              duration={3000}
                              triggerElement={'#crystal'}
                              offset={width > 940 ? 1000 : 100}
                            >
                              {progress => (
                                <Tween
                                  from={{
                                    x: randomData[index].x,
                                    y: randomData[index].y,
                                    rotate: 0,
                                  }}
                                  to={{
                                    x: 0,
                                    y: 0,
                                    rotate: randomData[index].rotate,
                                  }}
                                  totalProgress={progress}
                                  paused
                                >
                                  <img
                                    src={item}
                                    className={style[`diamond${index + 1}`]}
                                  />
                                </Tween>
                              )}
                            </Scene>
                          );
                        })}
                      </div> */}
                      <div className={style.topText}>
                        <h3>
                          {LangData[lang].shadesOfGrey.crystal.title.map(
                            (item, index) => {
                              return (
                                <>
                                  {index !== 0 ? (
                                    <>
                                      <br />
                                      <span />
                                    </>
                                  ) : null}
                                  {item}
                                </>
                              );
                            }
                          )}
                        </h3>
                        <h4>Casa Dei Diamanti</h4>
                      </div>

                      {width > 940 ? (
                        <div className={style.center}>
                          <div className={style.left}>
                            <img src={CrystalMini} />
                            <h5>
                              {LangData[lang].shadesOfGrey.crystal.subTitle.map(
                                (item, index) => {
                                  return (
                                    <>
                                      {index !== 0 ? (
                                        <>
                                          <br />
                                          <span />
                                        </>
                                      ) : null}
                                      {item}
                                    </>
                                  );
                                }
                              )}
                            </h5>
                          </div>
                          <div className={style.right}>
                            <img src={CrystalBig} />
                          </div>
                        </div>
                      ) : (
                        <div className={style.mobileCenter}>
                          <img src={CrystalMobile1} />
                          <img src={CrystalMobile2} />
                        </div>
                      )}
                      <div id="tripleW" className={style.tripleWrapper}>
                        <img src={Crystal1} />
                        {width > 940 ? (
                          <>
                            <img src={Crystal2} />
                            <img src={Crystal3} />
                          </>
                        ) : (
                          <Scene
                            duration={1000}
                            triggerElement={'#tripleW'}
                            pin={'#tripleW'}
                            triggerHook={0}
                          >
                            {progress => (
                              <Tween
                                from={{
                                  x: 100,
                                }}
                                to={{
                                  x: -399,
                                }}
                                totalProgress={progress}
                                paused
                              >
                                <div className={style.mobileImageWrapper}>
                                  <img src={Crystal2} />
                                  <img src={Crystal3} />
                                </div>
                              </Tween>
                            )}
                          </Scene>
                        )}
                      </div>
                      <TextPart lang={lang} second />
                    </div>
                  </>
                );
              }}
            </Scene>
          </Controller>
        </div>
      ) : null}
    </>
  );
};

export default Crystal;
