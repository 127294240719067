import { useEffect, useState } from 'react';
import style from './address.module.scss';
import AddressForm from './AddressForm';
import axios from 'axios';
import { ApiRoutes } from '../../../utils/ApiRoutes';
import { LangData } from '../../../utils/LangData';
import { useSelector } from 'react-redux';

const AddressCard = ({
  click,
  title,
  name,
  phone,
  address,
  tc,
  city,
  selected,
  id,
  editClick,
}) => {
  const currentLang = useSelector(state => state.settings.setting.lang);
  return (
    <div className={style.addressCard}>
      <button
        onClick={click}
        className={`${style.select} ${selected === id && style.active}`}
      >
        <div className={style.square} />
        <span>{title}</span>
      </button>
      <div className={style.info}>
        <h3>{name}</h3>
        <h4>{phone}</h4>
        <h5>
          {address} <br /> {city}
        </h5>
        <h6>TCKN {tc}</h6>
      </div>
      <button onClick={editClick} className={style.edit}>
      {LangData[currentLang].basket.edit}
      </button>
    </div>
  );
};

// const data = [
//   {
//     title: 'ADRES İŞYERİ',
//     name: 'TOLGA TAŞCI',
//     phone: '*******7926',
//     address: 'Maslak Mahallesi Ahi Evran Caddesi No 6 Maslak',
//     city: 'Sarıyer İstanbul',
//     tc: '************1111',
//     id: 1,
//   },
//   {
//     title: 'ADRES EV',
//     name: 'TOLGA TAŞCI',
//     phone: '*******7926',
//     address: 'Maslak Mahallesi Ahi Evran Caddesi No 6 Maslak',
//     city: 'Sarıyer İstanbul',
//     tc: '************1111',
//     id: 2,
//   },
// ];

const Address = ({ selectedAdress, selectedBillAdress, selected }) => {
  const [bill, setBill] = useState(true);
  const [isForm, setForm] = useState(false);
  const [data, setData] = useState();
  const [editData, setEditData] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);

  console.log('selectedAdress', selected);

  useEffect(() => {
    axios
      .get(ApiRoutes.address.get)
      .then(res => {
        console.log('addressdata', res.data);
        setData(res.data.reverse());
      })
      .catch(err => {
        console.log(err);
      });
  }, [isForm, editData]);

  return (
    <div className={style.address}>
      {data ? (
        <>
          {isForm || editData ? (
            <AddressForm
              doneForm={e => {
                setForm(false);

                if (e) {
                  setEditData('');
                }
              }}
              editData={editData}
            />
          ) : (
            <div onClick={() => setForm(true)} className={style.title}>
              <button>+ {LangData[currentLang].basket.addressButton}</button>
            </div>
          )}
          <div className={style.addressWrapper}>
            <h2>{LangData[currentLang].basket.deliveryAddress}</h2>

            {data.map(item => {
              return (
                <AddressCard
                  title={item.caption}
                  tc={'************1111'}
                  name={item.name}
                  phone={`${item.phone.replace(/^.{5}/g, '*****')}`}
                  address={item.address}
                  city={`${item.district} / ${item.province}`}
                  selected={selected.address}
                  id={item.id}
                  click={() => {
                    selectedAdress(item);
                  }}
                  editClick={async () => {
                    await setEditData('');
                    await setEditData(item);
                  }}
                />
              );
            })}
          </div>
          <button
            onClick={() => setBill(!bill)}
            className={`${style.billButton} ${bill && style.active}`}
          >
            <div className={style.square} />
            <span>{LangData[currentLang].basket.sameAddress}</span>
          </button>
          {!bill ? (
            <div className={style.addressWrapper}>
              <h2>{LangData[currentLang].basket.invoiceAddress}</h2>
              {data.map(item => {
                return (
                  <AddressCard
                    title={item.caption}
                    tc={'************1111'}
                    name={item.name}
                    phone={`${item.phone.replace(/^.{5}/g, '*****')}`}
                    address={item.address}
                    city={`${item.district} / ${item.province}`}
                    selected={selected.billAddress}
                    id={item.id}
                    click={() => {
                      selectedBillAdress(item);
                    }}
                    editClick={async () => {
                      await setEditData('');
                      await setEditData(item);
                    }}
                  />
                );
              })}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default Address;
