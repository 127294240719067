import { PageWrapper } from '../../components';
import style from './satis.module.scss';

const SatisSozlesmesi = () => {
  return (
    <PageWrapper>
      <div className={style.satisSozlesmesi}>
        <h1>Mesafeli Satış Sözleşmesi</h1>
        <p>
          Bu Sözleşme, ALICI (Tüketici)'nın, mobil cihazındaki uygulama ile
          işlem yaptığı halleri de kapsamak üzere SATICI'ya ait
          https://www.fratellidiamanti.com/ elektronik ticaret internet sitesine
          ("İNTERNET SİTESİ") sipariş vererek satın almak istediği aşağıda
          belirtilen ürün/hizmetlerin ("Ürün/Ürünler") ALICI'ya satışı-teslimi
          ve diğer hususlara ilişkin olarak tarafların hak ve yükümlülüklerini
          düzenler. ALICI bu Sözleşme'yi İNTERNET SİTESİ'nde onayladıktan sonra,
          sipariş verdiği Ürün(ler)'in bedeli ve masrafları seçtiği ödeme
          yöntemi ile tahsil olunur.
        </p>
        <h2>Madde1: TARAFLAR</h2>
        <table>
          <tr>
            <th>
              SATICI : CEO GRUP KOZMETİK SANAYİ VE TİCARET LİMİTED ŞİRKETİ
            </th>
            <th />
          </tr>
          <tr>
            <td>Unvanı :</td>
            <td>FRATELLI DIAMANTI</td>
          </tr>
          <tr>
            <td>Adres :</td>
            <td>
              TOPÇULAR MAHALLESİ TOPÇU ÇEŞMESİ SOKAK NO:3-5/1
              EYÜPSULTAN/İSTANBUL
            </td>
          </tr>
          <tr>
            <td>Telefon :</td>
            <td>(0212) 501 9313</td>
          </tr>
          <tr>
            <td>Fax :</td>
            <td>(0212) 501 93 15</td>
          </tr>
          <tr>
            <td>E-Posta :</td>
            <td>info@ceogrup.com.tr</td>
          </tr>
          <tr>
            <td>Banka Hesabı :</td>
            <td>
              GARANTİ BANKASI ( BAYRAMPAŞA ŞUBESİ) TR:4500 0620 0050 2000 0629
              7648
            </td>
          </tr>
          <tr>
            <td>Vergi No:</td>
            <td>206 067 6664</td>
          </tr>
          <tr>
            <td>Mersis No :</td>
            <td>0206067666400014</td>
          </tr>
          <tr>
            <td>Satıcı Temsilcisi (var ise) :</td>
            <td></td>
          </tr>
          <tr>
            <td>Satıcı Temsilcisinin Açık : Adresi (var ise):</td>
            <td></td>
          </tr>
          <tr>
            <td>ALICI (TÜKETİCİ):</td>
            <td></td>
          </tr>
          <tr>
            <td>Adı,Soyadı/ Ünvanı :</td>
            <td></td>
          </tr>
          <tr>
            <td>Adres :</td>
            <td></td>
          </tr>
          <tr>
            <td>Telefon :</td>
            <td></td>
          </tr>
          <tr>
            <td>E-Posta :</td>
            <td></td>
          </tr>
        </table>

        <h2>Madde 2: SÖZLEŞME KONUSU ÜRÜN, FİYAT, ÖDEME VE TESLİMAT</h2>
        <p>
          Ürünlerin (malların/hizmetlerin) Cinsi ve Türü, Miktarı, Marka/Modeli/
          Rengi, Brim Fiyat(lar)ı ve Satış Bedeli ile Ödeme (tahsilat) Bilgileri
          ve ALICI'nın bildirdiği teslim yeri dahil Teslimat Bilgileri aşağıda
          belirtildiği gibidir. Teslimatı yapacak kargo firmasının ALICI'nın
          bulunduğu yerde şubesi olmadığı takdirde ALICI'nın SATICI tarafından
          bildirilecek bir diğer yakın şubesinden teslim alması gerekmektedir
          (Bu hususta ALICI'ya gerekli bilgilendirme, e-posta/mail, SMS veya
          telefonla yapılacaktır). Teslimat ile ilgili diğer konular
          Sözleşme'nin aşağıdaki 7. maddesinde belirtilmiştir.
        </p>
        <table>
          <tr>
            <th>ÜRÜN</th>
            <th>BİRİM FİYAT (TL)</th>
            <th>ADET</th>
            <th>TOPLAM (TL)</th>
          </tr>
          <tr>
            <td>Shades Of Grey</td>
            <td>2.490,00 TL</td>
            <td>1</td>
            <td>2.490,00 TL</td>
          </tr>
          <tr>
            <td>Kullanılan hediye çeki / Paracık / _______ toplamı</td>
            <td />
            <td />
            <td>___ TL</td>
          </tr>
          <tr>
            <td>Sipariş işlem ve gönderim-kargo ücretleri</td>
            <td />
            <td />
            <td>Gönderici ödemeli</td>
          </tr>
          <tr>
            <td>SİPARİŞ ÖDEME TOPLAMI (KDV Dahil)</td>
            <td />
            <td />
            <td>2.490,00TL</td>
          </tr>
        </table>
        <p>
          {`Ödeme (Tahsilat) Bilgileri
Ödeme Şekli-Aracı : (Kredi Kartı / Kapıda Kredi Kartlı veya Nakit Tahsilat) 
[Kart Tipi Visa ....
Kart Numarası ....
Ödeme Kredi Kartı ...
Kredi Kartına Taksit/Tek Çekim .
Toplam Tutar ... ]`}
        </p>
        <table>
          <tr>
            <th>Teslimat Bilgileri :</th>
            <th />
            <th />
          </tr>
          <tr>
            <td>Adı,Soyadı/ Unvanı :</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Adres :</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Telefon :</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>E-Posta :</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>Fatura Bilgileri :</th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Adı,Soyadı/ Unvanı :</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Adres :</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Telefon :</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>E-Posta :</td>
            <td></td>
            <td></td>
          </tr>
        </table>
        <p>
          ALICI’nın sipariş esnasında ürünü seçtiği Fratelli Diamanti
          Mağazası’ndan teslim yöntemiyle teslimat seçeneğini seçmesi halinde,
          ürün Seçili Fratelli Diamanti Mağazası’na teslimatı yapacak lojistik
          firması tarafından ALICI’nın seçmiş olduğu teslimat noktasına en geç
          15 gün içerisinde teslim edilecektir. Ürünün teslimat noktasına
          bırakılmasının ardından ALICI’nın İNTERNET SİTESİ’ne bildirdiği/
          kaydolurken iletmiş olduğu telefon numarasına/ e-posta adresine gelen
          kod ile en geç 14 iş günü içerisinde 10.00-22.00 Saatleri arasında
          ürünü teslim alacaktır. ALICI’nın işbu süre içerisinde ürünü teslim
          almaması halinde ürünü iade ettiği kabul edilecek olup ürün bedeli,
          siparişi gerçekleştirdiği kredi kartı/banka hesabına iade edilecektir.
          Opsiyonlama hizmetinde Fratelli Diamanti Mağazası’ndan teslim yöntemi
          bulunmamaktadır. ALICI, siparişlerine ilişkin bilgi almak ve
          şikayetlerini iletmek için “ +90 212 386 20 00” müşteri hizmetleri
          numarasını ücretsiz olarak arayabilir ve “ onlinedestek@aymarka.com.tr
          ” mail adresine yazılı bildirimde bulunabilir.
        </p>
        <h2>Madde 3- ALICI'NIN ÖNCEDEN BİLGİLENDİRİLDİĞİ HUSUSLAR</h2>
        <p>
          ALICI, aşağıdaki hususlarda, bu Sözleşme'nin ALICI tarafından İNTERNET
          SİTESİ'nde kabulü ile kurulmasından ve gerek siparişi, gerek ödeme
          yükümlülüğü altına girmesinden önce İNTERNET SİTESİ'nin ilgili
          sayfaları-kısımlarındaki tüm genel-özel açıklamaları görerek ve
          inceleyerek bilgilendiğini teyiden kabul eder. - SATICI'nın unvanı ve
          iletişim bilgileri ile güncel tanıtıcı bilgileri, - Ürün(ler)'in
          İNTERNET SİTESİ'nden alınması sırasındaki satış işlemi aşamaları ile
          yanlış girilen bilgilerin düzeltilmesine ilişkin amaca uygun
          araçlar-yöntemler, - SATICI'nın mensubu olduğu Meslek Odası
          (İTO-İstanbul Ticaret Odası) ve ITO'nun meslek ile ilgili öngördüğü
          davranış kuralları bilgisinin edinebileceği elektronik iletişim
          bilgileri (Telefon: 4440486, www.ito.org.tr) - SATICI tarafından
          uygulanan ALICI bilgileri için geçerli gizlilik, veri kullanımı-işleme
          ve ALICI'ya elektronik iletişim kuralları ile ALICI'nın bu hususlarda
          SATICI'ya verdiği izinler, ALICI'nın kanuni hakları, SATICI'nın
          hakları ve tarafların haklarını kullanım usulleri, - Ürünler için
          SATICI tarafından öngörülen gönderim kısıtlamaları, - Sözleşme konusu
          Ürün(ler) için SATICI tarafından kabul edilen ödeme yöntem-araçları
          ile Ürünler'in temel özellik-nitelikleri, vergiler dahil toplam fiyatı
          (ilgili masraflar da dahil olmak üzereALICI'nın SATICI'ya ödeyeceği
          toplam bedel), - Ürüler'in ALICI'ya teslimine ilişkin usuller ile
          nakliye-teslim-kargo masrafları hakkında bilgiler, - Ürünler ile
          ilgili diğer ödeme/tahsilat ve teslimat bilgileri ile Sözleşme'nin
          ifasına ilişkin bilgiler, bu hususlarda tarafların
          taahhüt-sorumlulukları, - ALICI'nın cayma hakkına sahip olmadığı
          Ürünler ve diğer mal-hizmetler, - ALICI'nın cayma hakkının olduğu
          durumlarda bu hakkını kullanma şartları, süresi ve usulü ile hakkın
          süresinde kullanılmaması durumunda ALICI'nın cayma hakkını
          kaybedeceği, - Cayma hakkı olan Ürünler'de, Ürün cayma süresi içinde
          kullanım talimatlarına, olağan işleyişine veya teknik özelliklerine
          uygun kullanılmamasından ötürü bozulduğu veya bir değişikliğe uğradığı
          takdirde ALICI'nın cayma talebinin kabul edilmeyebileceği ve her
          durumda SATICI'ya karşı sorumlu olacağı, SATICI'nın kabul ettiği
          hallerde söz konusu bozukluğa veya değişime göre uygun bulduğu bir
          tutarı ALICI'ya yapacağı geri ödemeden tenzil (mahsup) ile tahsil
          edebileceği, - Cayma hakkının bulunduğu durumlarda Ürünleri SATICI'ya
          ne şekilde iade edebileceği ve ilgili tüm mali hususlar (iade yolları,
          masrafı ve Ürün bedelinin iadesi ve iade sırasında ALICI tarafından
          kazanılmış/kullanılmış ödül puanları için yapılabilecek indirim ve
          mahsuplar dahil), - ALICI'nın tüzel kişi olması halinde, ticari ya da
          mesleki amaçlarla satın aldığı Ürünler için (örneğin toplu alımlar
          herhalükarda bu nitelikte sayılır) cayma hakkı başta olmak üzere
          "tüketici haklarını" kullanamayacağı, - Mahiyetine göre bu Sözleşme'de
          de yer alan diğer tüm satış şartları ile işbu Sözleşme ALICI
          tarafından İNTERNET SİTESİ'nde onaylanarak kurulduktan sonra ALICI'ya
          elektronik posta ile gönderildiğinden ALICI tarafından istenen süre
          ile saklanıp buradan erişilebileceği, SATICI'nın da üç yıl süre ile
          nezdinde saklayabileceği. - Uyuşmazlık hallerinde ALICI'nın SATICI'ya
          şikayetlerini iletebileceği iletişim bilgileri ile yasal başvurularını
          6502 Sayılı Kanun'un ilgili hükümlerine uygun olarak İlçe/İl Hakem
          Heyetlerine ve Tüketici Mahkemeleri'ne yapabileceği.
        </p>
        <p>
          -- ALICI, siparişine ilişkin bilgi almak ve şikayetlerini iletmek için
          “ +90 212 386 20 00 “ müşteri hizmetleri numarasını ücretsiz olarak
          arayabilir ve “ destek@fratellidiamanti.com “ mail adresine yazılı
          bildirimde bulunabilir.
        </p>
        <h2>Madde 4- CAYMA HAKKI</h2>
        <p>
          ALICI Ürün'ü teslim aldığı tarihten itibaren ondört (14) gün içinde
          herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin bu
          Sözleşme'den cayma hakkına sahiptir. Ancak kanunen şu mal/hizmetlere
          ilişkin sözleşmelerde, kullanılmamış/istifade edilmemiş olsa dahi,
          cayma hakkı bulunmamaktadır : ALICI, iade edilecek ürünü sadece
          SATICI’nın anlaşmalı olduğu kargo firmalarına teslim edebilir. Bu
          kargo firmaları dışında herhangi bir kargo şirketiyle SATICI’ya
          ulaştırılan ürünlerin iadesi ve ulaşım bedeli kabul edilmemektedir.
          Yapılan iadeler mutlak surette original kutu veya ambalajı ile
          birlikte yapılacak olup, original kutusu/ambalajı bozulmuş (örnek
          olarak; orijinal kutu üzerine kargo etiketi yapıştırılmış ve/veya
          kargo koli bandı ile bantlanmış ürün vb.) tekrar satılabilirlik
          özelliğini kaybetmiş, başka bir müşteri tarafından satın alınamayacak
          durumda olan ürünlerin iadesi kabul edilmemektedir. ALICI iade etmek
          istediği ürün ile birlikte original sevk irsaliyesi ve iade sebebini
          içeren bir dilekçe ile SATICI’ya ulaştırır. ALICI’nın siparişi iptal
          etmek istemesi halinde mail adresi, telefon numarası veya “+90 212 386
          20 00” müşteri hizmetleri numarası üzerinden iptal talebinde bulunması
          gerekmektedir. Bunun üzerine SATICI, ALICI’ya iptal işleminin
          gerçekleştiğine dair e-posta kanalıyla bildirim yapmaktadır. Belirtmek
          gerekir ki, iptal işlemlerinde ürün kargoya verilmemiş ise, ücretin
          tamamı işlemin gerçekleştirdiği yöntem ile iade edilecek olup, ürünün
          kargoya verilmesi halinde kargo ücreti mahsup edilmek suretiyle
          ALICI’ya iade yapılmaktadır.
        </p>

        <p>
          a) ALICI'nın özel istekleri veya onun kişisel ihtiyaçları
          doğrultusunda hazırlanan mallar (üzerinde değişiklik ya da ilaveler
          yapılarak kişiye/kişisel ihtiyaçlara özel hale getirilenler, ALICI
          siparişine istinaden yurt içinden veya dışından ithal/temin edilen
          özel Ürünler dahil)
          <br /> b) kozmetik vb.leri ile çikolata vb. gıda maddeleri gibi çabuk
          bozulabilen veya son kullanma tarihi geçebilecek mallar <br />
          c) yine kozmetik, mayo, iç giyim mamülleri vb. teslimden sonra
          ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış ve iadesi
          sağlık-hijyen açısından uygun olmayan mallar
          <br /> d) teslimden sonra başka ürünlerle karışan ve doğası gereği
          ayrıştırılması mümkün olmayan mallar
          <br /> e) ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış
          kitap, CD, DVD, ses ve görüntü kayıtları, yazılımlar vb. digital
          içerikli her türlü ürünler ile bilgisayar sarf malzemeleri; vi)
          elektronik ortamda anında ifa edilen tüm hizmetler ve tüketiciye
          anında teslim edilen her türlü gayri maddi mallar <br />
          f) fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen
          ve satıcı/sağlayıcının kontrolünde olmayan mal veya hizmetler,
          <br /> g) abonelik sözleşmesi kapsamında sağlananlar dışında, gazete
          ve dergi gibi süreli yayınlar
          <br /> h) belirli bir tarihte veya dönemde yapılması gereken,
          konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve
          eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesi
          hizmeti
          <br /> i) ALICI onayı ile cayma hakkı süresi içinde ifasına başlanan
          hizmetler ve <br />
          j) genel olarak ilgili mevzuat uyarınca mesafeli satış kapsamı dışında
          kabul edilen diğer mal-hizmetler ile ALICI'nın ticari/mesleki amaçla
          satın alma yaptığı haller. Cayma hakkı kullanımı mümkün olan hallerde,
          ALICI, cayma süresi içinde malı, işleyişine, teknik özelliklerine ve
          kullanım talimatlarına uygun bir şekilde kullanmadığı takdirde meydana
          gelen değişiklik ve bozulmalardan kanun gereği sorumludur. Buna göre,
          cayma tarihine kadarki süreçte Ürün'ün kullanım talimatlarına, teknik
          özelliklerine ve işleyişine uygun bir şekilde kullanılmamasından ötürü
          değişiklik veya bozulma olursa ALICI cayma hakkını kaybedebilir;
          SATICI tarafından kabul edildiği hallerde, iade edilecek Ürün
          bedelinden değişiklik / bozulma kadar indirim yapılır. Cayma hakkı
          bulunan hallerde ALICI'nın cayma hakkını kullandığına dair açık bir
          bildirimi yasal 14 günlük süre içinde SATICI'ya yöneltmiş (yukarıda
          belirtilen iletişim adreslerine sözlü/yazılı iletmiş) olması
          yeterlidir. Söz konusu hakkın süresi içerisinde kullanılması
          durumunda, Ürün'ün azami on (10) gün içerisinde, giderleri ALICI'ya
          ait olmak üzere SATICI'nın yukarıdaki adresine gönderilmesi
          zorunludur. INTERNET SİTESİ'nde ürün iadeleri için anlaşmalı kargo
          firması belirtilmiş ise, ALICI Ürün'ü bulunduğu İlçe dahilindeki veya
          harindeki bir şubesinden gönderebilir, bu takdirde ALICI'dan masraf
          alınmaz. Bu iade işleminde Ürün'ün kutusu, ambalajı, varsa standart
          aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi
          gerekmektedir. Ayrıca vergi mevzuatı gereği, ALICI tarafından kanunen
          İade Faturası kesilmesi gereken hallerin yanı sıra Ürünle beraber iade
          edilecek olan fatura üzerinde, aşağıda belirtilen, iade ile ilgili
          bölüm doldurulup imzalanacaktır. Faturası kurumlar (tüzel kişiler)
          adına düzenlenen sipariş iadeleri, İade Faturası kesilmediği takdirde
          kabul edilmeyecektir). "Ürünün iade edileceği adres, SATICI adresi /
          iade için teslim olunan kargo firması adresi." Yukarıdaki belirtilen
          gereklerin ALICI tarafından yerine getirilmesi kaydı ile, cayma
          bildiriminin SATICI'ya ulaştığı tarihten itibaren 14 gün içinde, Ürün
          bedeli ve varsa Ürün'ün ALICI'ya teslim masrafları ALICI'ya, Ürün'ü
          satın alırken kullandığı ödeme aracına uygun bir şekilde iade edilir.
          ALICI'nın Ürünler'e ilişkin cayma süresi sonrasındaki kanuni
          hakları-sorumlulukları ile SATICI'nın ALICI'dan olan, ödül puanlarına
          ilişkin bulunları da kapsamak üzere akdi ve kanuni tahsil-mahsup
          hakları dahil hak ve yükümlülükleri ayrıca mevcut ve geçerlidir.
          Satıcı mal/hizmetin ifasının imkânsızlaştığı hallerde; mücbir
          sebepler, ifa imkansızlığı veya teslimatı engelleyen olağanüstü
          durumlarda, durumu öğrendiği tarihten itibaren 3 (üç) gün içerisinde
          Tüketici’ye bildirir. Bu durumda Satıcı teslimat masrafları da dahil
          olmak üzere tahsil edilen tüm ödemeleri bildirim tarihinden itibaren
          en geç 14 (ondört) gün içinde Tüketici’ye iade eder.
        </p>
        <h2>
          MADDE 5 - ALICI'NIN SÖZLEŞME KONUSU ÜRÜNÜ SATIN ALIRKEN ÖDÜL PUANLARI
          KAZANDIĞI VE/VEYA ÖDÜL PUANLARI KULLANARAK SATICI'YA ÖDEME YAPTIĞI
          HALLERDE UYGULANACAK ÖZEL ŞARTLAR{' '}
        </h2>
        <p>
          5.1.Ödül puanları vb.lerini kazandıran bir kuruluş ile ALICI ve SATICI
          arasında, ödül puanlarının SATICI'ya ait İNTERNET SİTESİ'nden
          alışverişlerde indirim vb. sağlamasına imkan veren cari bir
          anlaşmanın-sözleşmenin varlığında, ALICI, SATICI'nın söz konusu
          anlaşması ve keza kendisinin anılan kuruluş ile sözleşmesinin gereği
          olarak bu Sözleşme konusu alışverişi sebebi ile öyle bir ödül puanı
          kazanmış ise, işbu Sözleşme'den cayılması ve sair suretle
          feshi/sipariş iptali ile ALICI'ya bir geri ödeme yapılması mevzubahis
          olan hallerde, ALICI tarafından bu Sözleşme konusu satın alma ile
          kazanılmış ödül puanları, hediyeler ve benzerlerinin tutarı (parasal
          değeri) ALICI'dan geri alınır. Şöyleki; bu geri alma işlemi,
          SATICI'nın ilgili kuruluş ile anlaşmasında farklı bir yöntem
          öngörülmedikçe, ALICI'nın anılan kuruluş-sistem nezdinde (işbu
          Sözleşme konusu alışveriş ile kazanılmış ödül puanları hariç)
          yeterli-başka ödül puanı mevcut ise öncelikle o ödül puanlarından,
          mevcut değil ise SATICI'nın ALICI'ya iade edeceği bedelden nakden
          indirilerek (mahsup edilerek) yapılır <br /> 5.2. ALICI tarafından
          işbu Sözleşme konusu Ürün alışverişinde SATICI'ya ödeme kısmen/tamamen
          ödül puanları vb. ile yapılmış ise, bu suretle satın alınan Ürün'ün bu
          Sözleşme'nin ilgili hükümlerine göre Ürün bedeli ALICI'ya geri
          ödenecek biçimde iadesinin söz konusu olduğu hallerde, ALICI'nın
          Ürün'ü İNTERNET SİTESİ'nde satın alırken SATICI nezdindeki kullandığı
          ödül puanı ve benzerleri, SATICI'nın ilgili kuruluş ile farklı bir
          anlaşması olmadıkça, ALICI'ya (yine puan olarak) iade edilebilir.
          <br /> 5.3. ALICI tarafından herhangi bir surette haksız ödül puanı
          kazanımı veya kullanımının tespit edildiği durumlarda geçerli bir
          genel kural olarak, söz konusu ödül puanlarının parasal değeri-tutarı
          SATICI tarafından ALICI'dan (kredi kartından, nakden ve sair yasal
          yöntemler ile) tahsil olunabilecektir. Bu hüküm, öyle bir sistemin
          uygulaması neticesi SATICI tarafından ALICI'ya hediye verilen malların
          bedeli için de geçerlidir.
          <br /> 5.4. Ödül puanı ve benzerlerinin kazanım ve kullanım işlemleri
          ilgili diğer hususlar, söz konusu kuruluş ile ALICI ve SATICI
          arasındaki anlaşma-sözleşmelerin hükümlerine tabi olup, ilgili
          hallerde SATICI, gerek burada, gerek anılan sözleşme-anlaşmalarda
          belirli tüm hak-yetkileri ALICI ve kuruluş nezdinde kullanabilir,
          ilgili işlemleri belirtilen kuruluş ve/veya aynı sistemdeki diğer
          işyerleri adına ve/veya hesabına da yapabilir.
          <br /> 5.5. SATICI'dan kazanılan veya SATICI nezdinde kullanılan ödül
          puanları, hediye çekleri vb. mukabili nakdi para talepleri, hiçbir
          durumda ve surette kabul edilmez.
          <br /> 5.6. SATICI, ALICI ile yukarıda anılan kuruluşlar arasındaki
          ihtilaflar ile bunların herhangi bir maddi, hukuki, mali ve mali
          olmayan sonucu için hiçbir mesuliyet kabul etmemektedir; yukarıdaki
          hükümler geçerli ve saklıdır. <br /> 5.7. Yukarıdaki hükümler, varsa,
          Tüketici'nin doğrudan SATICI'dan edindiği ödül puanı kazanım ve
          kullanımlarında da kıyasen uygulanır. İNTERNET SİTESİ/SATICI'dan ödül
          puanı kazanan veya SATICI'ya ödemelerinde ödül puanı vb.lerini
          kullanan tüm tüketiciler, böylece ALICI, yukarıdaki özel şartları (da)
          kabul etmiş olmaktadır.
        </p>
        <h2>
          MADDE 6 - GÜVENLİK-GİZLİLİK, KİŞİSEL BİLGİLER, ELEKTRONİK İLETİŞİMLER
          VE FİKRİ-SINAİ HAKLAR İLE İLGİLİ KURALLAR
        </h2>
        <p>
          INTERNET SİTESİ'nde bilgilerin korunması, gizliliği,
          işlenmesi-kullanımı ve iletişimler ile diğer hususlarda aşağıda cari
          esasları belirtilen gizlilik kuralları-politikası ve şartlar
          geçerlidir.
          <br /> 6.1.ALICI tarafından İNTERNET SİTESİ'nde girilen bilgilerin ve
          işlemlerin güvenliği için gerekli önlemler, SATICI tarafındaki sistem
          altyapısında, bilgi ve işlemin mahiyetine göre günümüz teknik
          imkanları ölçüsünde alınmıştır. Bununla beraber, söz konusu bilgiler
          ALICI cihazından girildiğinden ALICI tarafında korunmaları ve ilgisiz
          kişilerce erişilememesi için, virüs ve benzeri zararlı uygulamalara
          ilişkin olanlar dahil, gerekli tedbirlerin alınması sorumluluğu
          ALICI'ya aittir.
          <br /> 6.2. ALICI'nın İNTERNET SİTESİ'ne üyeliği ve alışverişleri
          sırasında edinilen bilgileri SATICI, dahil olduğu grup şirketler
          bünyesindeki Beymen Perakende ve Tekstil Yatırımları A.Ş.,.(Beymen,
          Beymen Club) ve Beymen İç ve Dış Tic. A.Ş. ile her türlü
          bilgilendirme, reklam-tanıtım, promosyon, satış, pazarlama, mağaza
          kartı, kredi kartı ve üyelik uygulamaları amaçlı yapılacak elektronik
          ve diğer ticari-sosyal iletişimler için, belirtilenler ve halefleri
          nezdinde süresiz olarak veya öngörecekleri süre ile kayda alınabilir,
          basılı/manyetik arşivlerde saklanabilir, gerekli görülen hallerde
          güncellenebilir, paylaşılabilir, aktarılabilir, transfer edilebilir,
          kullanılabilir ve sair suretlerle işlenebilir. Bu veriler ayrıca
          kanunen gereken durumlarda ilgili Merci ve Mahkemelere iletilebilir.
          ALICI kişisel olan-olmayan mevcut ve yeni bilgilerinin, kişisel
          verilerin korunması hakkında mevzuat ile elektronik ticaret mevzuatına
          uygun biçimde yukarıdaki kapsamda kullanımına, paylaşımına,
          işlenmesine ve kendisine ticari olan-olmayan elektronik iletişimler ve
          diğer iletişimler yapılmasına muvafakat ve izin vermiştir.
          <br /> 6.3. ALICI SATICI'ya belirtilen iletişim kanallarından ulaşarak
          veri kullanımı-işlenmelerini ve/veya iletişimleri her zaman için
          durdurabilir. ALICI'nın bu husustaki açık bildirimine göre, kişisel
          veri işlemleri ve/veya tarafına iletişimler yasal azami süre içinde
          durdurulur; ayrıca dilerse, hukuken muhafazası gerekenler ve/veya
          mümkün olanlar haricindeki bilgileri, veri kayıt sisteminden silinir
          ya da kimliği belli olmayacak biçimde anonim hale getirilir. ALICI
          isterse kişisel verilerinin işlenmesi ile ilgili işlemler, aktarıldığı
          kişiler, eksik veya yanlış olması halinde düzeltilmesi, düzeltilen
          bilgilerin ilgili üçüncü kişilere bildirilmesi, verilerin silinmesi
          veya yok edilmesi, otomatik sistemler ile analiz edilmesi sureti ile
          kendisi aleyhine bir sonucun ortaya çıkmasına itiraz, verilerin kanuna
          aykırı olarak işlenmesi sebebi ile zarara uğrama halinde giderilmesi
          gibi konularda SATICI'ya her zaman yukarıdaki iletişim kanallarından
          başvurabilir ve bilgi alabilir. Bu hususlardaki başvuru ve talepleri
          yasal azami süreler içinde yerine getirilecek yahut hukuki gerekçesi
          tarafına açıklanarak kabul edilmeyebilecektir.
          <br /> 6.4. INTERNET SİTESİ'ne ait her türlü bilgi ve içerik ile
          bunların düzenlenmesi, revizyonu ve kısmen/tamamen kullanımı
          konusunda; SATICI'nın anlaşmasına göre diğer üçüncü sahıslara ait
          olanlar hariç; tüm fikri-sınai haklar ve mülkiyet hakları SATICI'ya
          aittir.
          <br /> 6.5. SATICI yukarıdaki konularda gerekli görebileceği her türlü
          değişikliği yapma hakkını saklı tutar; bu değişiklikler SATICI
          tarafından INTERNET SİTESİ'nden veya diğer uygun yöntemler ile
          duyurulduğu andan itibaren geçerli olur.
          <br /> 6.6. INTERNET SİTESİ'nden ulaşılan diğer sitelerde kendilerine
          ait gizlilik-güvenlik politikaları ve kullanım şartları geçerlidir,
          oluşabilecek ihtilaflar ile menfi neticelerinden SATICI sorumlu
          değildir.
        </p>
        <h2>Madde 7- GENEL HÜKÜMLER</h2>
        <p>
          7.1. Sözleşme konusu Ürün, yasal 15 günlük süre aşılmamak koşulu ile
          ALICI'ya veya İNTERNET SİTESİ'nde gösterdiği adresteki üçüncü
          kişi/kuruluşa aşağıda ve işbu Sözleşme’nin 2. maddesinde belirtilen
          esaslarda teslim edilir. SATICI, Ürünler'i, gönderileri için anlaşmalı
          kargo firması aracılığı ile göndermekte ve teslim ettirmektedir. Bu
          kargo firmasının ALICI'nın bulunduğu yerde şubesi olmaması halinde,
          ALICI'nın Ürünü, kargo firmasının, SATICI tarafından bildirilen bir
          diğer yakın şubesinden teslim alması gerekmektedir. Stoklarda olan
          Ürünler, sipariş tarihinden itibaren, en geç üç (3) iş günü içerisinde
          kargoya teslim edilir. Ancak, aynı sipariş içinde kampanyalı Ürün'ler
          var ise kampanyanın sona ermesi beklenir ve sonrasında en geç 3 (üç)
          iş günü içinde, sipariş konusu bütün Ürün'ler TÜKETİCİ tarafından
          sipariş esnasında bildirilen kişi ve adrese teslim edilmek üzere kargo
          firmasına verilir. Kargo Firmaları, SATICI'dan teslim aldığı
          gönderileri, uzaklıklarına göre değişmekle birlikte normal şartlar
          altında ortalama 3 (üç) iş günü içerisinde ALICI'lara ulaştırmaktadır.
          <br />
          7.2 Genel olarak ve aksi açıkca belirtilmediği sürece teslimat
          masrafları (kargo ücreti vb.) ALICI'ya aittir. SATICI, satış anında
          yürüttüğü ve İNTERNET SİTESİ'nde şartlarını ilan ettiği kampanyalara
          bağlı olarak söz konusu teslimat masraflarının tamamını ya da bir
          kısmını ALICI'ya yansıtmayabilir.
          <br /> 7.3. Ürünlerin teslimatı anında ALICI'nın adresinde bizatihi
          bulunmaması ve adresteki kişilerin teslimatı kabul etmemesi durumunda
          SATICI, bu husustaki edimini yerine getirmiş kabul edilecektir.
          Adreste teslim alacak bir kimsenin olmaması durumunda kargo firması
          ile temas kurarak ürünlerin sevkiyatını takip etmek ALICI'nın
          sorumluluğundadır. Ürün, ALICI'dan başka bir kişi/kuruluşa teslim
          edilecek ise, teslim edilecek kişi/kuruluşun adresinde bulunmaması
          veya teslimatı kabul etmemesinden SATICI sorumlu tutulamaz. Bu
          hallerde ALICI'nın Ürün'ü geç teslim almasından kaynaklanan her türlü
          zarar ile Ürün'ün kargo şirketinde beklemiş olması ve/veya kargonun
          SATICI'ya iade edilmesinden dolayı oluşan giderler de ALICI'ya aittir.{' '}
          <br />
          7.4. ALICI, Ürün'ü teslim aldığı anda kontrol etmekle ve Ürün'de
          kargodan kaynaklanan bir sorun gördüğünde, Ürün'ü kabul etmemek ve
          Kargo firması yetkilisine tutanak tutturmakla sorumludur. Aksi halde
          SATICI sorumluluk kabul etmeyecektir.
          <br /> 7.5. ALICI'nın, SATICI tarafından aksi yazılı öngörülmemiş ise,
          Ürün'ü teslim almadan önce bedelini tamamen ödemiş olması gerekir.
          Peşin satışlarda teslimattan önce Ürün bedeli SATICI'ya tamamen
          ödenmediği, taksitli satışlarda vadesi gelen taksit tutarı tediye
          edilmediği takdirde SATICI, tek taraflı olarak sözleşmeyi iptal
          edebilir ve Ürün'ü teslim etmeyebilir. Ürün teslimatı sonrasında
          herhangi bir sebepten dolayı, işlem yapılan kredi kartının ait olduğu
          Banka/finansman kurumunun Ürün bedelini SATICI'ya ödememesi ya da
          ödediği bedeli geri talep etmesi halinde, Ürün en geç 3 gün içinde
          ALICI tarafından SATICI'ya iade edilir. Ürün bedelinin ödenmemesi
          ALICI'nın bir kusurundan veya ihmalin kaynaklanıyor ise kargo
          masrafları ALICI tarafından karşılanacaktır. SATICI'nın iadeyi kabul
          etmeksizin Ürün bedeli alacağını takip dahil diğer tüm akdi-kanuni
          hakları ayrıca ve her halükarda saklıdır. Tereddüte mahal vermemek
          bakımından; ALICI'nın satış bedelini, bankalardan (finansman
          kuruluşları dahil) sahip olduğu kredi kartı, taksit kart v.b. ile
          ödediği hallerde, bu kartların sağladığı tüm imkanlar doğrudan kartı
          veren kuruluşça sağlanmış kredi ve/veya taksitli ödeme imkanlarıdır;
          bu çerçevede gerçekleşen ve SATICI'nın bedelini defaten veya peyder
          pey tahsil ettiği Ürün satışları işbu Sözleşme'nin tarafları yönünden
          kredili veya taksitli satış değildir, peşin satıştır. SATICI'nın
          kanunen taksitle satış sayılan hallerdeki yasal hakları
          (taksitlerinödenmemesi halinde sözleşmeyi fesih ve/veya kalan borcun
          tümünün temerrüt faizi ile birlikte ödenmesini talep hakları dahil)
          ilgili mevuzat çerçevesinde mevcut ve saklıdır. ALICI'nın temerrüdü
          durumunda yürürlükteki kanunların öngördüğü şekilde aylık oranda
          temerrüt faizi tatbik edilir.
          <br /> 7.6. Ürün'ün normal satış/teslimat koşulları dışında olağanüstü
          durumlar (hava muhalefeti, yoğun trafik, deprem, sel, yangın gibi)
          nedeni ile yasal azami 15 günlük süre zarfında teslim edilememesi söz
          konusu ise, SATICI teslimat ile ilgili olarak ALICI'yı bilgilendirir.
          Bu durumda ALICI siparişi iptal edebilir, benzer bir ürün sipariş
          edebilir veya olağanüstü durum sonuna kadar bekleyebilir.
          <br /> 7.7. SATICI Sözleşme konusu Ürün'ü tedarik edemeyeceğinin
          anlaşılması halinde, bu durumu öğrendiği tarihten itibaren üç (3) gün
          içinde açık bir şekilde ALICI'yı kanuna uygun yöntemle bilgilendirmek
          ve sözlü/yazılı onayını almak kaydıyla, eşit kalite-fiyatta başka bir
          mal/hizmeti tedarik edebilir ve Sözleşme konusu taahhüdünü bu suretle
          yerine getirmiş sayılır. ALICI söz konusu onayı verip vermemek te
          herbakımdan serbesttir ve onay vermediği hallerde sipariş iptaline
          (Sözleşme feshine) ilişkin akdi-kanuni hükümler uygulanır.
          <br />
          7.8. Sözleşme-kanuna uygun caymalar dahil sipariş iptallerinde ve
          Sözleşme fesihlerinde, Ürün bedeli tahsil edilmiş ise, azami 14 gün
          içinde ALICI'ya iade edilir. Aşağıdaki kuralın gerekleri saklıdır.
          İade ALICI'nın SATICI'ya Ürün bedeli tediyesinde kullandığı ödeme
          aracına uygun bir şekilde yapılır. Örneğin Kredi kartlı ödemelerde
          iade işlemi de ALICI kredi kartına iade sureti ile yapılır ve Ürün
          tutarı, siparişin ALICI tarafından iptal edilmesinden sonra aynı
          sürede ilgili bankaya iade edilir; bu tutarın bankaya iadesi işleminin
          SATICI tarafından yapılmasında sonra ALICI hesaplarına yansıması
          tamamen banka işlem süreci ile ilgili olduğundan, ALICI olası
          gecikmeler için SATICI'nın herhangi bir şekilde müdahalesi ve
          sorumluluk üstlenmesinin mümkün olamayacağını şimdiden kabul eder
          (bankaların iadeyi ALICI hesabına yansıtma işlemleri genellikle üç
          haftayı bulabilmektedir). SATICI'nın iade edilecek bedel için, bu
          Sözleşme ve kanundan doğan mahsup, indirim ve tenzilat hakları vardır
          ve saklıdır. Sözleşme'nin SATICI'nın edimini ifa etmemesinden ötürü
          ALICI tarafından feshedildiği hallere ilişkin ALICI kanuni hakları da
          saklı ve mevcuttur. <br />
          7.9. ALICI Ürün ve satışla ilgili talep ve şikayetlerini, Sözleşme'nin
          giriş kısmındaki SATICI iletişim kanallarından ulaşarak, sözlü veya
          yazılı şekilde SATICI'ya bildirebilir. <br />
          7.10. Yukarıda 3. maddede yazılı hususların bir kısmı mahiyeti itibari
          ile bu Sözleşme'de yer almayabilir; bununla beraber, ALICI tarafından
          İNTERNET SİTESİ'nde görülen/onaylanan Ön Bilgilendirmelerde, keza
          İNTERNET SİTESİ'nin --ilgisine göre satış aşamaları veya genel--
          bilgilendirme sayfaları/bölümlerinde yer almaktadırlar. <br />
          7.11. ALICI, kabulü sonrasında bildirdiği e-posta (mail) adresine
          gönderildiklerinden, söz konusu Bilgilendirmelere ve bu Sözleşme'ye,
          anılan maili cihazına kaydedip saklayarak her zaman ulaşabilir ve
          inceleyebilir. Öte yandan, SATICI nezdindeki sistemlerde üç yıl süre
          ile muhafaza edilir. <br />
          7.12. Bu Sözleşme'den ve/veya uygulanmasından doğabilecek her türlü
          uyuşmazlığın çözümünde SATICI kayıtları (bilgisayar-ses kayıtları gibi
          manyetik ortamdaki kayıtlar dahil) delil oluşturur; tarafların ilgili
          emredici yasal düzenlemelerden bu hususta doğan hakları geçerli ve
          saklıdır.
        </p>
        <h2>
          Madde 8- ALICI'NIN KANUNİ BAŞVURU YOLLARI - YETKİLİ YARGI MERCİLERİ
        </h2>
        <p>
          Bu Sözleşme'den doğabilecek uyuşmazlıklarda, Gümrük ve Ticaret
          Bakanlığı'nca yasa gereği her yıl belirlenen-ilan edilen parasal
          sınırlar dâhilinde İl ve İlçe Tüketici Hakem Heyetleri, bu sınırları
          aşan durumlarda Tüketici Mahkemeleri görevli-yetkilidir. ALICI, bu
          çerçevede, kendisinin veya dilerse SATICI'nın yerleşim yerindeki
          (ikametgahındaki) Hakem Heyetleri ve Tüketici Mahkemeleri'ne
          başvurabilir. ALICI, işbu Sözleşme'de ve ayrılmaz parçasını oluşturan
          sipariş-sözleşme ön bilgilendirmelerinde (İNTERNET SİTESİ'nde) yazılı
          tüm koşulları ve açıklamaları okuduğunu, satışa konu Ürün/Ürün'lerin
          temel özellik-nitelikleri, satış fiyatı, ödeme şekli, teslimat
          koşulları, SATICI ve satışa konu Ürün ile ilgili diğer tüm ön
          bilgiler-bilgilendirmeler ve cayma hakkı ile kişisel
          bilgiler-elektronik iletişim ve ödül puanları koşulları dahil bu
          Sözleşme'nin 3. maddesinde yazılı bütün hususlarda önceden bilgi
          sahibi olduğunu, tamamını İNTERNET SİTESİ'nde elektronik ortamda
          gördüğünü ve yine tüm bunlara elektronik ortamda
          teyit-onay-kabul-iznini vererek Ürün'ü sipariş ile işbu Sözleşme
          hükümlerini kabul ettiğini kabul ve beyan eder. Gerek söz konusu ön
          bilgilendirmeler, gerek bu Sözleşme, ALICI'nın SATICI'ya bildirdiği
          yukarıdaki elektronik posta (mail) adresine de gönderilmekte, anılan
          elektronik postada siparişin alındığı teyidi sipariş özeti ile
          birlikte ayrıca yer almaktadır.
        </p>
      </div>
    </PageWrapper>
  );
};

export default SatisSozlesmesi;
