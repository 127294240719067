import style from './productimagegrid.module.scss';
import Image1 from '../../assets/img/dummy/image-grid-1.png';
import Image2 from '../../assets/img/dummy/image-grid-2.png';
import Image3 from '../../assets/img/dummy/image-grid-3.png';

const ProductImageGrid = ({ width, data }) => {
  return (
    <div className={style.productImageGrid}>
      {width > 940 ? (
        <>
          <img src={data[0].link} alt="1" />
          <img src={data[1].link} alt="2" />
          <img src={data[2].link} alt="3" />
        </>
      ) : (
        <>
          <img src={data[0].link} alt="1" />
          <div>
            <img src={data[1].link} alt="2" />
            <img src={data[2].link} alt="3" />
          </div>
        </>
      )}
    </div>
  );
};

export default ProductImageGrid;
