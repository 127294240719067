import {
  ProductDetail,
  Home,
  Login,
  Cart,
  Register,
  Kvkk,
  SatisSozlesmesi,
  ReturnPolicy,
  CeyhanElmas,
  StoreList,
  Profile,
  PaymentStatus,
  ShadesOfGrey,
  Contact,
  Collection,
  Parfum,
  ProductPage,
} from '../containers';

export const routes = [
  {
    title: 'Home',
    path: '*',
    component: <Home route={''} />,
    protected: false,
  },

  {
    title: 'ShadesOfGrey',
    path: '/shades-of-grey',
    component: <ShadesOfGrey route={''} />,
    protected: false,
  },
  {
    title: 'Collection',
    path: '/koleksiyon',
    component: <Collection route={''} />,
    protected: false,
  },
  {
    title: 'Parfum',
    path: '/parfum',
    component: <Parfum route={''} />,
    protected: false,
  },
  {
    title: 'Product Detail',
    path: '/product/:id',
    component: <ProductDetail route={''} />,
    protected: false,
  },
  {
    title: 'Product Page',
    path: '/discover/:id',
    component: <ProductPage route={''} />,
    protected: false,
  },
  {
    title: 'Product Page',
    path: '/discover/shades-of-grey',
    component: <ShadesOfGrey route={''} />,
    protected: false,
  },
  {
    title: 'Login',
    path: '/login',
    component: <Login route={''} />,
    protected: false,
  },
  {
    title: 'Register',
    path: '/register',
    component: <Register route={''} />,
    protected: false,
  },
  {
    title: 'Cart',
    path: '/cart',
    component: <Cart route={''} />,
    protected: true,
  },
  {
    title: 'Kvkk',
    path: '/kvkk',
    component: <Kvkk route={''} />,
    protected: false,
  },
  {
    title: 'Mesafeli-satis-sozlesmesi',
    path: '/mesafeli-satis-sozlesmesi',
    component: <SatisSozlesmesi route={''} />,
    protected: false,
  },
  {
    title: 'İptal Iade Koşulları',
    path: '/iptal-iade-kosullari',
    component: <ReturnPolicy route={''} />,
    protected: false,
  },
  {
    title: 'Ceyhan Elmas',
    path: '/ceyhan-elmas',
    component: <CeyhanElmas route={''} />,
    protected: false,
  },
  {
    title: 'Store',
    path: '/store',
    component: <StoreList route={''} />,
    protected: false,
  },
  {
    title: 'Profile',
    path: '/profile',
    component: <Profile route={''} />,
    protected: true,
  },
  {
    title: 'Profile',
    path: '/profile/:id',
    component: <Profile route={''} />,
    protected: true,
  },
  {
    title: 'Payment Success',
    path: '/payment/success',
    component: <PaymentStatus success route={''} />,
    protected: true,
  },
  {
    title: 'Payment Error',
    path: '/payment/error',
    component: <PaymentStatus error route={''} />,
    protected: true,
  },
  {
    title: 'Contact',
    path: '/contact',
    component: <Contact error route={''} />,
    protected: false,
  },
];
