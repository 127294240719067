import ProductImage from '../../../assets/img/home/home-product-image.png';
import ProductImage2 from '../../../assets/img/home/render_png.png';
import style from './productcard.module.scss';
import { useNavigate } from 'react-router-dom';
import { MoreSignatureIcon } from '../../../assets/icon';
import { LangData } from '../../../utils/LangData';

const ProductCard = ({ lang }) => {
  const navigate = useNavigate();
  return (
    <div className={style.productCard}>
      <div className={style.info}>
        <h3>
          {LangData[lang].shadesOfGrey.product.title.map((item, index) => {
            return (
              <>
                {index !== 0 ? <br /> : null}
                {item}
              </>
            );
          })}
        </h3>
        <h4>
          {LangData[lang].shadesOfGrey.product.subTitle.map((item, index) => {
            return (
              <>
                {index !== 0 ? <br /> : null}
                {item}
              </>
            );
          })}
        </h4>
      </div>
      <div className={style.card}>
        <img src={ProductImage2} />
        <h4>Shades Of Grey</h4>
        <p>DIAMOND COLLECTION - EXTRAIT DE PARFUM</p>
        <button
          onClick={() => navigate(LangData[lang].shadesOfGrey.product.buy.path)}
        >
          {LangData[lang].shadesOfGrey.product.buy.title}
        </button>
        {/* <p>
          Shades of Grey ile randevun var. Sadece biraz sabırlı ol, ruh halinin
          değişmesine çok az kaldı.{' '}
        </p> */}
      </div>
      <MoreSignatureIcon />
    </div>
  );
};

export default ProductCard;
