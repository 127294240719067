import { PageWrapper } from '../../components';
import style from './collection.module.scss';
import useWindowDimensions from '../../helpers/windowWidth';
import HeadImage from '../../assets/img/collection/head-image.png';
import Ambre from '../../assets/img/collection/ambre.png';
import Ciello from '../../assets/img/collection/ciello.png';
import Milano from '../../assets/img/collection/milano.png';
import Red from '../../assets/img/collection/red.png';
import Shades from '../../assets/img/collection/shades.png';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useSelector } from 'react-redux';
import { LangData } from '../../utils/LangData';

const Collection = () => {
  const { width } = useWindowDimensions();
  const [data, setData] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.collection.get}`)
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <>
      {data ? (
        <PageWrapper>
          <div className={style.head}>
            <img src={data[0].basePhoto} />
            <div className={style.title}>
              <h2>
                {LangData[currentLang].collection.title[0]}
                <br />
                {LangData[currentLang].collection.title[1]}
              </h2>
              {/*  burası ingilizce gelicek  */}
              <p
                dangerouslySetInnerHTML={{
                  __html: data[0].baseText[currentLang],
                }}
              />
            </div>
          </div>

          <div className={style.feedWrapper}>
            {data.map(item => {
              return (
                <a href={`/discover/${item.slug}`} className={style.feed}>
                  <img src={item.photo} />
                  <h4>{item.title[currentLang]}</h4>
                  <a href={`/discover/${item.slug}`}>KEŞFET</a>
                </a>
              );
            })}
          </div>
        </PageWrapper>
      ) : null}
    </>
  );
};

export default Collection;
