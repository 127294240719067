import style from './textpart.module.scss';
import { LangData } from '../../../utils/LangData';
import { useEffect, useState } from 'react';

const TextPart = ({ second, lang }) => {
  console.log('langg', lang);

  return (
    <div className={style.textPart}>
      <h3>
        Shades
        <br />
        of
        <br />
        Grey
      </h3>

      {second ? (
        <p>
          {LangData[lang].shadesOfGrey.textPart[1].map((item, index) => {
            return (
              <>
                {index !== 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
                {item}
              </>
            );
          })}
        </p>
      ) : (
        <p>
          {LangData[lang].shadesOfGrey.textPart[2].map((item, index) => {
            return (
              <>
                {index !== 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
                {item}
              </>
            );
          })}
        </p>
      )}
    </div>
  );
};

export default TextPart;
