import style from './signature.module.scss';
import SignatureImage from '../../assets/img/brand/signature.png';
import SignatureImageMobile from '../../assets/img/brand/signature-mobile.png';
import SignatureIcon from '../../assets/img/brand/signature-image.png';
import { ElmasSignature } from '../../assets/icon';
import { LangData } from '../../utils/LangData';

const Signature = ({ width, lang }) => {
  return (
    <div className={style.signature}>
      <div className={style.text}>
        {width > 940 ? (
          <p>
            {LangData[lang].component.signature.text.desktop.map(
              (item, index) => {
                return (
                  <>
                    {index !== 0 ? (
                      <>
                        <br />
                      </>
                    ) : null}
                    {item}
                  </>
                );
              }
            )}
          </p>
        ) : (
          <p>
            {LangData[lang].component.signature.text.mobile.map(
              (item, index) => {
                return (
                  <>
                    {index !== 0 ? (
                      <>
                        <br />
                      </>
                    ) : null}
                    {item}
                  </>
                );
              }
            )}
          </p>
        )}
        <div className={style.bottom}>
          <img
            className={style.signatureIcon}
            alt="signature-icon"
            src={SignatureIcon}
          />
        </div>
      </div>
      <div className={style.imageWrapper}>
        <img
          className={style.image}
          alt="signature-main"
          src={width < 940 ? SignatureImage : SignatureImage}
        />
      </div>
    </div>
  );
};

export default Signature;
