import MobileHeadImage from '../../../assets/img/new-home/head/head-video.mp4';
import HeadImage from '../../../assets/img/new-home/head/head-video.mp4';
import style from './homehead.module.scss';
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import { LangData } from '../../../utils/LangData';

const HomeHead = ({
  width,
  lang,
  video,
  videoMobile,
  discoverLink,
  disableDiscover,
  text,
}) => {
  return (
    <div id="head" className={style.head}>
      <Controller>
        <Scene offset={-300} triggerElement="#trigger" duration={1000}>
          {progress => {
            console.log('progress', progress);
            return (
              <>
                <Tween totalProgress={progress} paused>
                  {/*<video autoPlay src={width > 940 ? HeadImage : MobileHeadImage} />*/}
                  <div className={style.video}>
                    <video key={video} playsInline autoPlay muted loop>
                      <source
                        src={
                          width > 940
                            ? video
                              ? video
                              : HeadImage
                            : videoMobile
                            ? videoMobile
                            : MobileHeadImage
                        }
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </Tween>

                <div
                  style={{
                    opacity: progress > 0.7 ? 0 : 1,
                  }}
                  className={style.info}
                >
                  <h1 dangerouslySetInnerHTML={{ __html: text }} />

                  {disableDiscover ? null : (
                    <a href={discoverLink ? discoverLink : '/'}>
                      {LangData[lang].home.head.discover}
                    </a>
                  )}
                </div>

                {width > 940 ? null : (
                  <div className={style.scroll}>
                    {LangData[lang].home.head.scroll}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="8"
                      viewBox="0 0 10 8"
                    >
                      <path
                        id="Polygon_2"
                        data-name="Polygon 2"
                        d="M5,0l5,8H0Z"
                        transform="translate(10 8) rotate(180)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                )}
              </>
            );
          }}
        </Scene>
      </Controller>
    </div>
  );
};

export default HomeHead;
