import style from './videocard.module.scss';
import VideoCover from '../../../assets/img/home/home-video-cover.png';
import PlayIcon from '../../../assets/icon/play-icon.png';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import VideoMain from '../../../assets/video/main-video.mp4';
import VideoMainMobile from '../../../assets/video/main-video-mobile.mp4';

const VideoCard = ({ width, video, videoMobile }) => {
  return (
    <>
      <div id="video" className={style.videoCard}>
        {width > 940 ? (
          <Controller>
            <Scene
              triggerHook={0}
              pin={'#video'}
              triggerElement="#video"
              duration={700}
            >
              {progress => (
                <>
                  <Tween
                    from={{
                      width: '60%',
                    }}
                    to={{
                      width: '100%',
                    }}
                    totalProgress={progress}
                    paused
                  >
                    <div className={style.video}>
                      {/* <img src={VideoCover} /> */}
                      <video autoPlay muted loop>
                        <source src={VideoMain} type="video/mp4" />
                      </video>
                      {/* <button className={style.play}>
                  <img src={PlayIcon} alt="play" />
                </button> */}
                    </div>
                  </Tween>
                </>
              )}
            </Scene>
          </Controller>
        ) : (
          <div className={style.video}>
            <video playsInline autoPlay muted loop>
              <source
                src={
                  width > 940 && video
                    ? video
                    : videoMobile
                    ? videoMobile
                    : VideoMainMobile
                }
                type="video/mp4"
              />
            </video>
            {/* <button className={style.play}>
                  <img src={PlayIcon} alt="play" />
                </button> */}
          </div>
        )}
      </div>
    </>
  );
};

export default VideoCard;
