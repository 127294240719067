import { PageWrapper, ProfileSidebar, ProfileAddress } from '../../components';
import ProductImage from '../../assets/img/dummy/product.png';
import style from './profile.module.scss';
import useWindowDimensions from '../../helpers/windowWidth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useLocation, useNavigate } from 'react-router-dom';

const Profile = () => {
  const { width } = useWindowDimensions();
  const [data, setData] = useState();
  const [addressData, setAdressData] = useState();
  const [selectedAdress, setSelectedAddress] = useState();
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[2];
  const navigate = useNavigate();

  const locationData = [
    {
      path: undefined,
      type: 'order',
      title: 'Siparişlerim',
    },
    {
      path: 'siparisler',
      type: 'order',
      title: 'Siparişlerim',
    },
    {
      path: 'adreslerim',
      type: 'address',
      title: 'Adreslerim',
    },
    {
      path: 'bilgilerim',
      type: 'profileInfo',
      title: 'Üyelik Bilgilerim',
    },
    {
      path: 'sifre-degistir',
      type: 'passChange',
      title: 'Şifre Değişikliği',
    },
    {
      path: 'help',
      type: 'help',
      title: 'Yardım',
    },
  ];

  const currentLocationData = locationData.filter(
    i => i.path === currentLocation
  )[0];
  // const data = [
  //   {
  //     no: 'XYZ02598614ABC',
  //     name: 'Shades Of Grey',
  //     status: 'Teslim Edildi',
  //     price: '3.150,00 TL',
  //     image: ProductImage,
  //   },
  //   {
  //     no: 'XYZ02598614ABC',
  //     name: 'Shades Of Grey',
  //     status: 'Teslim Edildi',
  //     price: '3.150,00 TL',
  //     image: ProductImage,
  //   },
  // ];

  const getOrderData = () => {
    axios
      .get(ApiRoutes.order.get)
      .then(res => {
        console.log('order', res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAddressData = () => {
    axios
      .get(ApiRoutes.address.get)
      .then(res => {
        setAdressData(res.data.reverse());
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrderData();
  }, []);

  return (
    <PageWrapper topBar text={'PROFILE'} link="/profile" logOut>
      {data ? (
        <div className={style.wrapper}>
          <ProfileSidebar
            locationData={locationData}
            currentLocation={currentLocation}
            name={'Tolga Taşçı'}
          />
          <div className={style.right}>
            {width > 940 ? (
              <div className={style.top}>
                <h1>{currentLocationData.title}</h1>
                <h3>2023</h3>
              </div>
            ) : (
              <div className={style.top}>
                <select
                  value={currentLocation}
                  onChange={e => {
                    navigate(`/profile/${e.target.value}`);
                  }}
                >
                  {locationData
                    .filter(i => i.path)
                    .map(item => {
                      return <option value={item.path}>{item.title}</option>;
                    })}
                </select>
              </div>
            )}

            {currentLocationData.type === 'order' ? (
              <div className={style.cardWrapper}>
                {data && data.length ? (
                  data.map(item => {
                    const currentDate = `${
                      item.date.split('T')[1].split(':')[0]
                    }:${item.date.split('T')[1].split(':')[1]} / ${
                      item.date.split('T')[0].split('-')[2]
                    }.${item.date.split('T')[0].split('-')[1]}.${
                      item.date.split('T')[0].split('-')[0]
                    }`;
                    return (
                      <div className={style.card}>
                        {width < 940 ? <img src={ProductImage} /> : null}
                        <div className={style.left}>
                          {width > 940 ? <img src={ProductImage} /> : null}
                          <div>
                            <h3>Date</h3>
                            <h5>{currentDate}</h5>
                          </div>
                          <div>
                            <h3>SİPARİŞ NO</h3>
                            <h5>{item.orderId}</h5>
                          </div>
                          <div>
                            <h3>ÜRÜN ADI</h3>
                            <h5>{item.productName}</h5>
                          </div>
                        </div>
                        <div className={style.right}>
                          <div>
                            <h3>DURUMU</h3>
                            <h5>{item.statu}</h5>
                          </div>
                          <div>
                            <h3>KREDİ KARTI</h3>
                            <h5>{item.price} TL</h5>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h6>Siparişiniz bulunmamaktadır.</h6>
                )}
              </div>
            ) : currentLocationData.type === 'address' ? (
              <ProfileAddress />
            ) : null}
          </div>
        </div>
      ) : null}
    </PageWrapper>
  );
};

export default Profile;
