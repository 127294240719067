import {
  PageWrapper,
  Head,
  FlashPhoto,
  Magazine,
  TripleCard,
  ProductCard,
  VideoCard,
  TextPart,
  HomeFooter,
  Crystal,
  Header,
  MobileMenu,
  Footer,
  BottomInfo,
  Newsletter,
} from '../../components';
import style from './shadesofgrey.module.scss';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../helpers/windowWidth';
import { useEffect, useState } from 'react';
import Triple1 from '../../assets/img/home/second-triple/triple-1.png';
import Triple2 from '../../assets/img/home/second-triple/triple-2.png';
import Triple3 from '../../assets/img/home/second-triple/triple-3.png';
import { Scene, Controller } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { LangData } from '../../utils/LangData';

const ShadesOfGrey = () => {
  const { width } = useWindowDimensions();
  const cartState = useSelector(state => state.cart.cart);
  const [basketData, setBasketData] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.basket.get}`)
      .then(async res => {
        console.log('basketData', res.data);
        await setBasketData(res.data);
      })
      .catch(err => {
        console.log(err);
      });

    localStorage.removeItem('redirectProduct');
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        background: scrollPosition > 9000 ? 'white' : '#0b0c0f',
      }}
      className={style.home}
    >
      {width < 940 ? (
        <MobileMenu
          lang={currentLang}
          home
          cart={basketData ? basketData : 0}
        />
      ) : (
        <Header lang={currentLang} home cart={basketData ? basketData : 0} />
      )}
      {scrollPosition < 50 ? (
        <a
          className={style.buy}
          href={LangData[currentLang].shadesOfGrey.buy.path}
        >
          {LangData[currentLang].shadesOfGrey.buy.title}
        </a>
      ) : null}
      <Head lang={currentLang} width={width} />
      <FlashPhoto width={width} />
      <div className={style.halfTitle}>
        {width > 940 ? (
          <h4>
            {LangData[currentLang].shadesOfGrey.halfTitle.desktop.map(
              (item, index) => {
                return (
                  <>
                    {index !== 0 ? <br /> : null}
                    {item}
                  </>
                );
              }
            )}
          </h4>
        ) : (
          <h4>
            {LangData[currentLang].shadesOfGrey.halfTitle.mobile.map(
              (item, index) => {
                return (
                  <>
                    {index !== 0 ? <br /> : null}
                    {item}
                  </>
                );
              }
            )}
          </h4>
        )}
      </div>
      <Magazine width={width} />
      <TripleCard width={width} />
      <Crystal lang={currentLang} width={width} />
      {width > 940 ? (
        <div className={style.triple}>
          <img src={Triple1} />
          <img src={Triple2} />
          <img src={Triple3} />
        </div>
      ) : (
        <Controller>
          <div id="triple222" className={style.tripleWrapper}>
            <img src={Triple1} />
            <Scene
              duration={1000}
              triggerElement={'#triple222'}
              pin={'#triple222'}
              triggerHook={0}
            >
              {progress => (
                <Tween
                  from={{
                    x: 100,
                  }}
                  to={{
                    x: -360,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <div className={style.mobileImageWrapper}>
                    <img src={Triple2} />
                    <img src={Triple3} />
                  </div>
                </Tween>
              )}
            </Scene>
          </div>
        </Controller>
      )}

      <TextPart lang={currentLang} width={width} />
      <VideoCard width={width} />
      <ProductCard lang={currentLang} width={width} />
      <BottomInfo lang={currentLang} />
      <Newsletter lang={currentLang} />
      <div className={style.footerWrapper}>
        <Footer lang={currentLang} width={width} />
      </div>
    </div>
  );
};

export default ShadesOfGrey;
