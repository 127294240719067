import { useState } from 'react';
import { NewsletterArrow } from '../../assets/icon';
import style from './newsletter.module.scss';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useEffect } from 'react';
import { LangData } from '../../utils/LangData';

const Newsletter = ({ lang }) => {
  const [isSuccess, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const setNewsletter = () => {
    axios
      .get(`${ApiRoutes.newsletter.set}?mail=${email}`)
      .then(res => {
        if (res.data === true) {
          setSuccess(true);
          setEmail('');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
  }, [isSuccess]);

  return (
    <div className={style.newsletter}>
      <h2>{LangData[lang].component.newsletter.title}</h2>
      <h3>{LangData[lang].component.newsletter.subTitle}</h3>
      <label>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="EMAIL"
          type="email"
        />
        <button
          onClick={() => {
            if (validateEmail(email)) {
              setNewsletter();
            }
          }}
        >
          <NewsletterArrow />
        </button>
      </label>
      {isSuccess ? (
        <h5>{LangData[lang].component.newsletter.success}</h5>
      ) : null}
    </div>
  );
};

export default Newsletter;
