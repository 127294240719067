import { PageWrapper, Newsletter } from '../../components';
import style from './storelist.module.scss';
import GoogleMapReact from 'google-map-react';
import MapIcon from '../../assets/img/map-icon.png';
import { StoreData } from '../../data/StoreData';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { LangData } from '../../utils/LangData';

const StoreList = () => {
  const [selectedData, setSelectedData] = useState({
    country: StoreData[0].country,
    city: '',
  });
  const currentLang = useSelector(state => state.settings.setting.lang);

  const [selectedLocation, setSelectedLocation] = useState([
    StoreData[0].city[0].longitude,
    StoreData[0].city[0].latitude,
  ]);

  const AnyReactComponent = ({ text }) => (
    <div className={style.mapIcon}>
      <img src={MapIcon} />
    </div>
  );

  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <div className={style.top}>
          <div className={style.mapWrapper}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyC5o2gnwvrMGpMLvbisTk_HdkbpnDXsHP4',
              }}
              defaultCenter={selectedLocation}
              center={selectedLocation}
              defaultZoom={17}
              defaultOptions={{
                styles: require(`../../data/MapStyle.json`),
              }}
            >
              <AnyReactComponent
                lat={selectedLocation[0]}
                lng={selectedLocation[1]}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
          <div className={style.listWrapper}>
            <h1>{LangData[currentLang].storeList.title}</h1>
            <div className={style.selectWrapper}>
              <select
                onChange={e => {
                  setSelectedData({
                    country: e.target.value,
                    city: '',
                  });
                }}
              >
                {StoreData.map(item => {
                  return <option value={item.country}>{item.country}</option>;
                })}
              </select>
              <select
                value={selectedData.city}
                onChange={e => {
                  setSelectedData({
                    ...selectedData,
                    city: e.target.value,
                  });
                }}
              >
                <option value={''} disabled selected>
                  {LangData[currentLang].storeList.selectCity}
                </option>
                {StoreData.filter(
                  i => i.country === selectedData.country
                )[0].city.map(item => {
                  return <option value={item.name}>{item.name}</option>;
                })}
              </select>
              <div className={style.buttonWrapper}>
                <button
                  onClick={() => {
                    const findLocation = StoreData.filter(
                      i => i.country === selectedData.country
                    )[0].city.filter(i => i.name === selectedData.city)[0];

                    if (selectedData.country && selectedData.city) {
                      setSelectedLocation([
                        findLocation.longitude,
                        findLocation.latitude,
                      ]);
                    }
                  }}
                >
                  {LangData[currentLang].storeList.showStore}
                </button>
                <button> {LangData[currentLang].storeList.location}</button>
              </div>
            </div>
            {selectedData.city ? (
              <div>
                <p>
                  <span>
                    {selectedData.city} - {selectedData.country}
                  </span>
                  {StoreData.filter(i => i.country === selectedData.country)[0]
                    .city.filter(i => i.name === selectedData.city)[0]
                    .description.map(item => {
                      return <span>{item}</span>;
                    })}
                  <span>
                    {LangData[currentLang].storeList.openToday}{' '}
                    {
                      StoreData.filter(
                        i => i.country === selectedData.country
                      )[0].city.filter(i => i.name === selectedData.city)[0]
                        .workHour
                    }
                  </span>
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default StoreList;
