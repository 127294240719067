export const FooterData = [
  {
    title: 'Fratelli Diamanti',
    links: [
      {
        title: 'Shades Of Grey',
        path: '/product/shades-of-grey',
      },
      {
        title: 'Ceyhan Elmas',
        path: '/ceyhan-elmas',
      },
    ],
  },
  {
    title: 'Mağazalar',
    links: [
      {
        title: 'Milano',
        path: '/store',
      },
      {
        title: 'Stuttgart',
        path: '/store',
      },

      {
        title: 'Dubai',
        path: '/store',
      },
      {
        title: 'Istanbul',
        path: '/store',
      },
    ],
  },
  {
    title: 'Contact',
    links: [
      {
        title: 'Bize Ulaşın',
        path: '/contact',
      },
      {
        path: '/product',
      },
    ],
  },
  {
    title: 'Legal Statement',
    links: [
      {
        title: 'Kişisel Verilerin Korunması Kanunu',
        path: '/kvkk',
      },
      {
        title: 'Mesafeli Satış Sözleşmesi',
        path: '/mesafeli-satis-sozlesmesi',
      },
      {
        title: 'İptal İade Koşulları',
        path: '/iptal-iade-kosullari',
      },
    ],
  },
];
