import style from './triplecard.module.scss';
import Triple1 from '../../../assets/img/home/triple/triple-image-1.png';
import Triple2 from '../../../assets/img/home/triple/triple-image-2.png';
import Triple3 from '../../../assets/img/home/triple/triple-image-3.png';
import TripleMobile from '../../../assets/img/home/triple/triple-mobile-image.png';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

const TripleCard = ({ width }) => {
  return (
    <>
      <div id="triple" className={style.tripleCard}>
        <Controller>
          <Scene
            pin={'#triple'}
            triggerElement="#triple"
            duration={1000}
            // duration={100}
            triggerHook={0}
            // pinSettings={{ pushFollowers: false }}
          >
            {progress => (
              <>
                <div className={style.tripleWrapper}>
                  {/* <Tween
                  from={{
                    height: 0,
                  }}
                  to={{
                    height: 800,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <div className={style.whitePart} />
                </Tween> */}
                  {width > 940 ? (
                    <Tween
                      from={{
                        height: 600,
                        left: 0,
                      }}
                      to={{
                        height: 600,
                        left: -1000,
                        // height: 600,
                        left: 0,
                      }}
                      totalProgress={progress}
                      paused
                    >
                      <img src={Triple1} />
                    </Tween>
                  ) : null}
                  <Tween
                    from={{
                      height: 600,
                    }}
                    to={{
                      height: 1000,
                      // height: 600,
                    }}
                    totalProgress={progress}
                    paused
                  >
                    <img className={style.center} src={Triple2} />
                  </Tween>
                  {width > 940 ? (
                    <Tween
                      from={{
                        height: 600,
                        right: 0,
                      }}
                      to={{
                        height: 600,
                        right: -1000,
                        // height: 600,
                        right: 0,
                      }}
                      totalProgress={progress}
                      paused
                    >
                      <img src={Triple3} />
                    </Tween>
                  ) : null}
                </div>
                <h3
                  style={
                    {
                      // opacity: progress > 0.6 ? 0 : 1,
                    }
                  }
                >
                  Shades
                  <br />
                  of
                  <br />
                  Grey
                </h3>
              </>
            )}
          </Scene>
        </Controller>
      </div>
      {/* {width < 940 ? <img className={style.mobile} src={TripleMobile} /> : null} */}
    </>
  );
};

export default TripleCard;
