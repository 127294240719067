import { PageWrapper, Newsletter } from '../../components';
import style from './contact.module.scss';
import GoogleMapReact from 'google-map-react';
import MapIcon from '../../assets/img/map-icon.png';
import { StoreData } from '../../data/StoreData';
import { useState } from 'react';
import ContactImage from '../../assets/img/contact/contact-image.png';

const StoreList = () => {
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <div className={style.top}>
          <div className={style.mapWrapper}>
            <img src={ContactImage} />
          </div>
          <div className={style.listWrapper}>
            <h1>Bize Ulaşın</h1>
            <p>FRATELLI DIAMANTI</p>
            <p>
              Reutlinger Str. 27, 72555 <br />
              Metzingen, Almanya
            </p>
            <p>+49 7123 9696155</p>
            <a href="mailto:info@fratellidiamanti.com" target="__blank">
              info@fratellidiamanti.com
            </a>
            <a href="/">www.fratellidiamanti.com</a>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default StoreList;
