import style from './bottominfo.module.scss';
import { InfoIcon } from '../../assets/icon';
import { LangData } from '../../utils/LangData';

const InfoCard = ({ icon, info }) => {
  return (
    <div className={style.infoCard}>
      <InfoIcon type={icon} />
      <h5>
        {info.map(i => {
          return (
            <>
              {i}
              <br />
            </>
          );
        })}
      </h5>
    </div>
  );
};

const BottomInfo = ({ lang }) => {
  return (
    <div className={style.bottomInfo}>
      {LangData[lang].component.bottomInfo.map(item => {
        return <InfoCard icon={item.icon} info={item.info} />;
      })}
      {/* <InfoCard icon={'message'} info={['Tüm Türkiye’ye Ücretsiz Gönderim']} /> */}
    </div>
  );
};

export default BottomInfo;
