import Magazine1 from '../../../assets/img/home/magazine/magazine-1.png';
import Magazine2 from '../../../assets/img/home/magazine/magazine-2.png';
import Magazine3 from '../../../assets/img/home/magazine/magazine-3.png';
import Magazine4 from '../../../assets/img/home/magazine/magazine-4.png';
import Magazine5 from '../../../assets/img/home/magazine/magazine-5.png';
import Magazine6 from '../../../assets/img/home/magazine/magazine-6.png';
import Magazine7 from '../../../assets/img/home/magazine/magazine-7.png';
import Magazine8 from '../../../assets/img/home/magazine/magazine-8.png';
import style from './magazine.module.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

const Magazine = ({ width }) => {
  return (
    <div id="trigger2" className={style.magazine}>
      <Controller>
        <Scene
          triggerElement="#trigger2"
          duration={2500}
          pin={'#trigger2'}
          offset={300}
        >
          {progress => (
            <Tween
              from={{
                left: 300,
              }}
              to={{
                left: width > 940 ? -1200 : -2120,
              }}
              totalProgress={progress}
              paused
            >
              <div className={style.wrapper}>
                <div className={style.magazineCard}>
                  <img src={Magazine1} />
                </div>
                <div className={style.magazineCard}>
                  <img src={Magazine2} />
                </div>
                <div className={style.magazineCard}>
                  <img src={Magazine3} />
                </div>
                <div className={style.magazineCard}>
                  <img src={Magazine4} />
                </div>
                <div className={style.magazineCard}>
                  <img src={Magazine5} />
                </div>
                <div className={style.magazineCard}>
                  <img src={Magazine6} />
                </div>
                <div className={style.magazineCard}>
                  <img src={Magazine7} />
                </div>
              </div>
            </Tween>
          )}
        </Scene>
      </Controller>
    </div>
  );
};

export default Magazine;
