import style from './productslider.module.scss';
import Slider from 'react-slick';
import { useRef, useState } from 'react';
import ProductImage1 from '../../assets/img/dummy/product-image-1.png';
import ProductImage2 from '../../assets/img/product/1.png';
import ProductImage3 from '../../assets/img/product/2.png';
import ProductVideo from '../../assets/img/product/3_1.mp4';
// import ProductImage3 from '../../assets/img/dummy/product-image-3.jpeg';

const ProductSlider = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const ContentSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    autoplaySpeed: 1500,
    autoplay: false,
    fade: true,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const sliderRef = useRef();

  return (
    <div className={style.sliderWrapper}>
      <div className={style.dotWrapper}>
        {data.map((item, index) => {
          return (
            <div
              className={`${style.dot} ${
                currentSlide === index && style.active
              }`}
              onClick={() => sliderRef.current.slickGoTo(index)}
            />
          );
        })}
      </div>
      <Slider ref={sliderRef} className={style.slider} {...ContentSettings}>
        {data.map(item => {
          return (
            <div className={style.contentCard}>
              {item.type === 'photo' ? (
                <img src={item.link} alt="content" />
              ) : (
                <video key={item.link} playsInline autoPlay muted loop>
                  <source src={item.link} type="video/mp4" />
                </video>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ProductSlider;
