import {
  Address,
  PageWrapper,
  Payment,
  Products,
  Summary,
} from '../../components';
import style from './cart.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { removeToCart } from '../../redux/actions';

const Cart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const section = location.search.split('section=')[1];
  const cartState = useSelector(state => state.cart.cart);
  const [data, setData] = useState();
  const [addressData, setAdressData] = useState();
  const [billAdressData, setBillAdressData] = useState();
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${ApiRoutes.basket.get}`)
      .then(async res => {
        console.log('basketData', res.data);
        await setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [update]);

  useEffect(() => {
    axios
      .get(ApiRoutes.address.get)
      .then(res => {
        setAdressData(res.data.reverse()[0].id);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <PageWrapper topBar={section}>
      {data && data.length ? (
        <div className={style.wrapper}>
          <div className={style.content}>
            {data && data.length === 0 ? (
              <div className={style.emptyCart}>
                <h3>Sepetinizde ürün bulunmamaktadır.</h3>
              </div>
            ) : section === 'product' ? (
              <Products
                updated={() => setUpdate(!update)}
                data={data}
                cart={cartState}
                deleteClick={e => {
                  axios
                    .post(ApiRoutes.basket.delete, {
                      productId: e.productId,
                      id: e.id,
                    })
                    .then(res => {
                      console.log(res.data);

                      setUpdate(!update);
                      dispatch(removeToCart());
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
              />
            ) : section === 'address' ? (
              <Address
                selectedAdress={e => setAdressData(e.id)}
                selectedBillAdress={e => setBillAdressData(e.id)}
                selected={{
                  address: addressData,
                  billAddress: billAdressData,
                }}
              />
            ) : section === 'payment' && addressData && data ? (
              <Payment
                address={addressData}
                billAdress={billAdressData}
                cart={data}
              />
            ) : null}
          </div>
          {data && data.length ? (
            <div className={style.summary}>
              <Summary
                click={() => {
                  if (section === 'product') {
                    navigate('/cart?section=address');
                  } else if (section === 'address' && addressData) {
                    navigate('/cart?section=payment');
                  }
                }}
                withProduct={section !== 'product'}
                cart={cartState}
                data={data}
                section={section}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </PageWrapper>
  );
};

export default Cart;
