import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/style/variables.module.scss';
import configureStore from './configureStore';
import { Provider } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import 'animate.css/animate.min.css';

export const store = configureStore();

export const state = store.getState();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
