import { PageWrapper } from '../../components';
import style from './kvkk.module.scss';

const Kvkk = () => {
  return (
    <PageWrapper>
      <div className={style.kvkk}>
        <iframe className={style.frame} src={'/kvkk-text.html'} />
      </div>
    </PageWrapper>
  );
};

export default Kvkk;
