import Cover from '../../assets/img/home/home-video-cover.png';
import PlayIcon from '../../assets/icon/play-icon.png';
import style from './productvideo.module.scss';
import VideoMain from '../../assets/video/main-video.mp4';
import VideoMainMobile from '../../assets/video/main-video-mobile.mp4';
import { useState } from 'react';

const ProductVideo = ({ width, data, cover }) => {
  const [isPlay, setPlay] = useState(false);
  console.log('cover', cover);
  return (
    <div onClick={() => setPlay(true)} className={style.productVideo}>
      {isPlay ? (
        <video loop autoPlay muted>
          <source
            src={width > 940 ? data.desktop : data.mobile}
            type="video/mp4"
          />
        </video>
      ) : (
        <img className={style.cover} alt="cover" src={cover} />
      )}

      {isPlay ? null : (
        <button onClick={() => setPlay(true)} className={style.play}>
          <img src={PlayIcon} alt="play" />
        </button>
      )}
    </div>
  );
};

export default ProductVideo;
