import style from './flashphoto.module.scss';
import FlashPhoto1 from '../../../assets/img/home/flash-photo/flash-1.png';
import FlashPhoto2 from '../../../assets/img/home/flash-photo/flash-2.png';
import FlashPhoto3 from '../../../assets/img/home/flash-photo/flash-3.png';
import FlashPhoto4 from '../../../assets/img/home/flash-photo/flash-4.png';
import FlashPhoto5 from '../../../assets/img/home/flash-photo/flash-5.png';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

const FlashPhoto = ({ width }) => {
  return (
    <div id="trigger" className={style.flashPhoto}>
      <Controller>
        <div className={style.photoWrapper}>
          <Scene
            triggerHook={0}
            duration={3000}
            offset={100}
            triggerElement={'#trigger'}
            pin={'#trigger'}
          >
            {progress => (
              <>
                <Scene triggerElement={'#trigger'}>
                  {progress => (
                    <Tween
                      from={{
                        top: -600,
                        opacity: 0,
                      }}
                      to={{
                        top: width > 940 ? -500 : -300,
                        opacity: 1,
                      }}
                      totalProgress={progress}
                      paused
                    >
                      <h3
                        style={{
                          top: progress > 0.06 ? 100 : 0,
                          opacity: progress > 0.06 ? 1 : 0,
                        }}
                      >
                        Shades
                        <br />
                        of
                        <br />
                        Grey
                      </h3>
                    </Tween>
                  )}
                </Scene>
                <div className={style.photoWrapper}>
                  <Scene
                    duration={200}
                    offset={100}
                    triggerElement={'#trigger'}
                  >
                    {progress => (
                      <Tween
                        from={{
                          height: width > 940 ? 550 : 200,
                          opacity: 0,
                          rotate: 0,
                          x: 600,
                        }}
                        to={{
                          height: width > 940 ? 657 : 320,
                          opacity: 1,
                          rotate: 10,
                          x: width > 940 ? -100 : -20,
                        }}
                        totalProgress={progress}
                        paused
                      >
                        <img src={FlashPhoto1} className={style.photo1} />
                      </Tween>
                    )}
                  </Scene>
                  <Scene
                    duration={200}
                    offset={1000}
                    triggerElement={'#trigger'}
                  >
                    {progress => (
                      <Tween
                        from={{
                          height: width > 940 ? 450 : 190,
                          opacity: 0,
                          x: -200,
                          rotate: 0,
                        }}
                        to={{
                          height: width > 940 ? 595 : 290,
                          opacity: 1,
                          x: width > 940 ? 200 : 50,
                          rotate: -10,
                        }}
                        totalProgress={progress}
                        paused
                      >
                        <img src={FlashPhoto2} className={style.photo2} />
                      </Tween>
                    )}
                  </Scene>
                  <Scene
                    duration={200}
                    offset={1500}
                    triggerElement={'#trigger'}
                  >
                    {progress => (
                      <Tween
                        from={{
                          height: width > 940 ? 390 : 160,
                          opacity: 0,
                          x: 550,
                          rotate: 0,
                        }}
                        to={{
                          height: width > 940 ? 491 : 240,
                          opacity: 1,
                          x: 0,
                          rotate: 5,
                        }}
                        totalProgress={progress}
                        paused
                      >
                        <img src={FlashPhoto3} className={style.photo3} />
                      </Tween>
                    )}
                  </Scene>
                  <Scene
                    duration={200}
                    offset={2000}
                    triggerElement={'#trigger'}
                  >
                    {progress => (
                      <Tween
                        from={{
                          height: width > 940 ? 350 : 120,
                          opacity: 0,
                          x: -450,
                        }}
                        to={{
                          height: width > 940 ? 435 : 214,
                          opacity: 1,
                          x: width > 940 ? -300 : -70,
                        }}
                        totalProgress={progress}
                        paused
                      >
                        <img src={FlashPhoto4} className={style.photo4} />
                      </Tween>
                    )}
                  </Scene>

                  <Scene
                    duration={200}
                    offset={3000}
                    triggerElement={'#trigger'}
                  >
                    {progress => (
                      <Tween
                        from={{
                          height: width > 940 ? 300 : 100,
                          opacity: 0,
                          x: 700,
                          rotate: 0,
                        }}
                        to={{
                          height: width > 940 ? 395 : 170,
                          opacity: 1,
                          x: width > 940 ? 80 : 20,
                          rotate: 10,
                        }}
                        totalProgress={progress}
                        paused
                      >
                        <img src={FlashPhoto5} className={style.photo5} />
                      </Tween>
                    )}
                  </Scene>
                </div>
              </>
            )}
          </Scene>
        </div>
      </Controller>
    </div>
  );
};

export default FlashPhoto;
