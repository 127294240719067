import { PageWrapper } from '../../components';
import ProductImage from '../../assets/img/dummy/product.png';
import style from './paymentstatus.module.scss';
import useWindowDimensions from '../../helpers/windowWidth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { SuccessIcon, ErrorIcon } from '../../assets/icon';

const PaymentStatus = ({ success, error }) => {
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        {success ? <SuccessIcon /> : <ErrorIcon />}
        <h1>{success ? 'Teşekkürler' : 'Üzgünüz'}</h1>
        <h2>
          {success ? 'ÖDEMENİZ BAŞARIYLA GERÇEKLEŞTİ' : 'ÖDEME BAŞARISIZ OLDU'}
        </h2>
      </div>
    </PageWrapper>
  );
};

export default PaymentStatus;
