import { PageWrapper, Signature } from '../../components';
import style from './ceyhanelmas.module.scss';
import CeyhanElmasHead from '../../assets/img/ceyhan-elmas/ceyhan-elmas-head.png';
import Ceyhan1 from '../../assets/img/ceyhan-elmas/ceyhan-elmas-1.png';
import Ceyhan2 from '../../assets/img/ceyhan-elmas/ceyhan-elmas-2.png';
import Ceyhan3 from '../../assets/img/ceyhan-elmas/ceyhan-elmas-3.png';
import Bottle from '../../assets/img/ceyhan-elmas/bottle.png';
import useWindowDimensions from '../../helpers/windowWidth';
import SignatureIcon from '../../assets/img/brand/signature-image.png';
import { LangData } from '../../utils/LangData';
import { useSelector } from 'react-redux';

const CeyhanElmas = () => {
  const { width } = useWindowDimensions();
  const currentLang = useSelector(state => state.settings.setting.lang);
  return (
    <PageWrapper link={'/ceyhan-elmas'} topBar text={'CEYHAN ELMAS'}>
      <div className={style.wrapper}>
        <div className={style.head}>
          {width < 940 ? (
            <h3>
              {LangData[currentLang].ceyhan.head.title.mobile.map(
                (item, index) => {
                  return (
                    <>
                      {index !== 0 ? <br /> : null}
                      {item}
                    </>
                  );
                }
              )}
            </h3>
          ) : (
            <h3>
              {LangData[currentLang].ceyhan.head.title.desktop.map(
                (item, index) => {
                  return (
                    <>
                      {index !== 0 ? <br /> : null}
                      {item}
                    </>
                  );
                }
              )}
            </h3>
          )}
          <img src={CeyhanElmasHead} />
        </div>
        <div className={style.info}>
          <div className={style.left}>
            <h5>Ceyhan Elmas</h5>
            {width > 940 ? null : (
              <h5>{LangData[currentLang].ceyhan.head.info.text}</h5>
            )}
            <img src={Bottle} />
          </div>
          <div className={style.right}>
            <p>
              {width > 940 ? LangData[currentLang].ceyhan.head.info.text : null}
            </p>
            <p>
              {LangData[currentLang].ceyhan.head.info.paragraph.map(
                (item, index) => {
                  return (
                    <>
                      {index !== 0 ? (
                        <>
                          <br />
                          <br />
                        </>
                      ) : null}
                      {item}
                    </>
                  );
                }
              )}
            </p>
          </div>
        </div>
        <Signature lang={currentLang} width={width} />
        <div className={style.imageWrapper}>
          <img src={Ceyhan1} />
          <img src={Ceyhan2} />
          <img src={Ceyhan3} />
        </div>
        <div className={style.bottom}>
          <h4>{LangData[currentLang].ceyhan.head.bottom.title}</h4>
          <img src={SignatureIcon} />
          <p>
            {LangData[currentLang].ceyhan.head.bottom.paragraph.map(
              (item, index) => {
                return (
                  <>
                    {index !== 0 ? (
                      <>
                        <br />
                      </>
                    ) : null}
                    {index === 2 ? (
                      <>
                        <br />
                      </>
                    ) : null}
                    {item}
                  </>
                );
              }
            )}
          </p>
        </div>
      </div>
    </PageWrapper>
  );
};

export default CeyhanElmas;
