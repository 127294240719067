import style from './olfactorynotes.module.scss';
import {
  BergamotIcon,
  BottleIcon,
  CashmereIcon,
  VanillaIcon,
  DoubleDiamondIcon,
} from '../../assets/icon';
import { LangData } from '../../utils/LangData';

const OlfactoryNotes = ({ lang, data }) => {
  console.log('data', data);

  return (
    <div className={style.oldfactory}>
      <h2>{LangData[lang].product.info.factory}</h2>
      <div className={style.content}>
        <div className={style.bottle}>
          <div className={style.icon1}>
            <BergamotIcon />
          </div>
          <div className={style.icon2}>
            <VanillaIcon />
          </div>
          <div className={style.icon3}>
            <CashmereIcon />
          </div>
          <div className={style.icon4}>
            <DoubleDiamondIcon />
          </div>
          <BottleIcon />
        </div>
        <div className={style.info1}>
          <h4>TOP NOTES</h4>
          <h5>
            {data.topNotes.map(item => {
              return (
                <>
                  {item.note}
                  <br />
                </>
              );
            })}
          </h5>
        </div>
        <div className={style.info2}>
          <h4>HEARTH NOTES</h4>
          <h5>
            {data.herathNotes.map(item => {
              return (
                <>
                  {item.note}
                  <br />
                </>
              );
            })}
          </h5>
        </div>
        <div className={style.info3}>
          <h4>BASE NOTES</h4>
          <h5>
            {data.baseNotes.map(item => {
              return (
                <>
                  {item.note}
                  <br />
                </>
              );
            })}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default OlfactoryNotes;
