import style from './register.module.scss';
import { PageWrapper, Newsletter } from '../../components';
import LoginImage from '../../assets/img/brand/login-image.png';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { BASE_URL } from '../../utils/config';
import { LangData } from '../../utils/LangData';
import { useSelector } from 'react-redux';

const Register = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(LangData[currentLang].register.error.email)
      .required(LangData[currentLang].register.error.required),
    password: Yup.string()
      .min(8, LangData[currentLang].register.error.password)
      .required(LangData[currentLang].register.error.required),
    passwordAgain: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        LangData[currentLang].register.error.passwordAgain
      )
      .required(LangData[currentLang].register.error.required),
    name: Yup.string()
      .min(3, LangData[currentLang].register.error.name)
      .required(LangData[currentLang].register.error.required),
    surname: Yup.string()
      .min(3, LangData[currentLang].register.error.surname)
      .required(LangData[currentLang].register.error.required),
    phone: Yup.string()
      .min(3, LangData[currentLang].register.error.phone)
      .required(LangData[currentLang].register.error.required),
    userName: Yup.string()
      .min(3, LangData[currentLang].register.error.username)
      .required(LangData[currentLang].register.error.required),
  });

  const registerSubmit = values => {
    console.log(values);

    axios
      .post(`${ApiRoutes.auth.register}`, {
        email: values.email,
        password: values.password,
        name: values.name,
        surname: values.surname,
        phone: values.phone,
        userName: values.userName,
      })
      .then(res => {
        console.log(res);
        if (res.data === true) {
          //   dispatch(
          //     userLogin({
          //       token: userData.token,
          //       userData: userData.account,
          //     })
          //   );
          const isRedirect = localStorage.getItem('redirectProduct');

          axios
            .post(`${ApiRoutes.auth.login}`, {
              username: values.userName,
              password: values.password,
            })
            .then(res => {
              console.log(res.data);
              if (res.data.item1 && res.data.item2) {
                localStorage.setItem('token', res.data.item2);

                axios.defaults.baseURL = BASE_URL;
                axios.interceptors.request.use(
                  async config => {
                    // const token = await AsyncStorage.getItem('token');

                    config.headers[
                      'Authorization'
                    ] = `Bearer ${res.data.item2}`;

                    return config;
                  },
                  error => {
                    return Promise.reject(error);
                  }
                );

                navigate(isRedirect ? '/product' : '/');
              } else if (res.data.item1 === null && res.data.item2 === null) {
                setError('loginError');
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <div className={style.left}>
          <img src={LoginImage} alt="login" />
        </div>
        <div className={style.right}>
          <h1>{LangData[currentLang].register.title}</h1>
          <Formik
            initialValues={{
              email: '',
              password: '',
              passwordAgain: '',
              name: '',
              surname: '',
              phone: '',
              userName: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={values => {
              registerSubmit(values);
              console.log(values);
              // createPayment(values);
            }}

            // innerRef={formikRef}
          >
            {({ errors, touched, values, handleSubmit }) => (
              <Form id="payment" onSubmit={handleSubmit}>
                <label>
                  <Field
                    type={'text'}
                    id={'name'}
                    name={'name'}
                    placeholder={LangData[currentLang].register.name}
                    value={values.name}
                  />
                </label>
                {errors.name && touched.name ? (
                  <span>{errors.name}</span>
                ) : null}
                <label>
                  <Field
                    type={'text'}
                    id={'surname'}
                    name={'surname'}
                    placeholder={LangData[currentLang].register.surname}
                    value={values.surname}
                  />
                </label>
                {errors.surname && touched.surname ? (
                  <span>{errors.surname}</span>
                ) : null}
                <label>
                  <Field
                    type={'text'}
                    id={'userName'}
                    name={'userName'}
                    placeholder={LangData[currentLang].register.userName}
                    value={values.userName}
                  />
                </label>
                {errors.userName && touched.userName ? (
                  <span>{errors.userName}</span>
                ) : null}
                <label>
                  <Field
                    type={'email'}
                    id={'email'}
                    name={'email'}
                    placeholder={LangData[currentLang].register.email}
                    value={values.email}
                  />
                </label>
                {errors.email && touched.email ? (
                  <span>{errors.email}</span>
                ) : null}
                <label>
                  <Field
                    type={'phone'}
                    id={'phone'}
                    name={'phone'}
                    placeholder={LangData[currentLang].register.phone}
                    value={values.phone}
                  />
                </label>
                {errors.phone && touched.phone ? (
                  <span>{errors.phone}</span>
                ) : null}

                <label>
                  <Field
                    type={'password'}
                    id={'password'}
                    name={'password'}
                    placeholder={LangData[currentLang].register.password}
                    value={values.password}
                  />
                </label>
                {errors.password && touched.password ? (
                  <span>{errors.password}</span>
                ) : null}
                <label>
                  <Field
                    type={'password'}
                    id={'passwordAgain'}
                    name={'passwordAgain'}
                    placeholder={LangData[currentLang].register.passwordAgain}
                    value={values.passwordAgain}
                  />
                </label>
                {errors.passwordAgain && touched.passwordAgain ? (
                  <span>{errors.passwordAgain}</span>
                ) : null}

                {error === 'loginError' ? (
                  <span>{LangData[currentLang].register.errorTitle}</span>
                ) : null}
                <button type="submit">
                  {LangData[currentLang].register.register}
                </button>
              </Form>
            )}
          </Formik>

          <a className={style.register} href="/login">
            {LangData[currentLang].register.login}
          </a>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Register;
